import { useCallback } from 'react';

import { ApolloClient, useApolloClient, useQuery } from '@apollo/client';
import produce from 'immer';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { v4 as uuid } from 'uuid';

import { useUpload } from 'client/app/api/filetree';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import { GraphQLWorkflow } from 'client/app/api/gql/utils';
import { buildParameterValue } from 'client/app/apps/workflow-builder/lib/elementParameterBuilders';
import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import * as AnthaHardcoded from 'client/app/cdn/catalogue/components/antha-hardcoded/antha-hardcoded';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import { AllDevicesQuery, ArrayElement, ElementSetQuery } from 'client/app/gql';
import cloneWithUUID from 'client/app/lib/workflow/cloneWithUUID';
import {
  buildConfiguredDevice,
  configHasDeletedDevice,
  configHasDeletedDeviceRunConfig,
  configHasOutdatedDeviceRunConfig,
} from 'client/app/lib/workflow/deviceConfigUtils';
import normaliseLayoutPositions from 'client/app/lib/workflow/normaliseLayoutPositions';
import { buildBundle, BuildBundleInput } from 'client/app/lib/workflow/types';
import {
  State as WorkflowState,
  useWorkflowBuilderDispatch,
} from 'client/app/state/WorkflowBuilderStateContext';
import { assignElementsToStages } from 'client/app/state/workflowBuilderStateUtils';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getArrayTypeFromAnthaType,
  getDefaultEditorForAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import {
  arrayIsFalsyOrEmpty,
  indexBy,
  isDefined,
  isFalsyOrEmptyObject,
} from 'common/lib/data';
import { getCopyName } from 'common/lib/format';
import { decodeBase64 } from 'common/lib/strings';
import { mapObject } from 'common/object';
import {
  BundleParameters,
  Connection,
  Connections,
  EditorType as WorkflowEditorType,
  Element,
  ElementInstance,
  FactorItem,
  Group,
  ParameterValueDict,
  ServerSideBundle,
  ServerSideElementInstance,
  ServerSideElements,
  Stage,
  WorkflowConfig,
  WorkflowEditMode,
} from 'common/types/bundle';
import { configHasNoDevices, isDataOnly } from 'common/types/bundleConfigUtils';
import { ParameterConfigurationSpec } from 'common/types/elementConfiguration';
import {
  DirectUploadSingleValueLegacy,
  FileObject,
  FileObjectLegacy,
} from 'common/types/fileParameter';
import { sanitizeFilenameForFiletree } from 'common/types/filetree';
import { Position2d } from 'common/types/Position';
import { SnackbarManager } from 'common/ui/components/SnackbarManager';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';

type GraphQLElementSet = ElementSetQuery['elementSet'];
// ElementSet_elementSet_elements as

type GraphQLElement = ArrayElement<GraphQLElementSet['elements']>;

/** Place a new element in the Workflow builder */
export function useCreateElementInstance() {
  const generateParameterValues = useGenerateParameterValues();
  return useCallback(
    async function createElementInstance(
      element: GraphQLElement,
      elementInstances: ElementInstance[],
      targetPosition: Position2d,
    ): Promise<{
      instance: ElementInstance;
      params: ParameterValueDict;
    }> {
      const { x, y } = targetPosition;
      const formattedElementName =
        element.configuration?.elementDisplayName ?? element.name.replace(/_/g, ' ');
      const indices = elementInstances
        .map(i => i.name)
        .filter(
          name => name.substr(0, formattedElementName.length) === formattedElementName,
        )
        .map(name => name.substr(formattedElementName.length))
        .filter(suffix => /^\s\d+$/.test(suffix))
        .map(index => parseFloat(index));

      const maxIndex = Math.max(0, ...indices);
      const name = `${formattedElementName} ${maxIndex + 1}`;

      let defaultParameters;

      // For now, if there's no configuration for the element, just use the old method
      // where defaults are taken from metadata information. We'll stop doing this when
      // we make configurations for existing elements en masse.
      if (element.configuration) {
        defaultParameters = buildDefaultParameters(element);
      } else {
        defaultParameters = await generateParameterValues(element);
      }

      return {
        instance: {
          Id: uuid(),
          TypeName: element.name,
          Meta: {
            x,
            y,
          },
          name,
          element,
        },
        params: defaultParameters,
      };
    },
    [generateParameterValues],
  );
}

const buildDefaultParameters = (element: GraphQLElement) =>
  mapObject(
    element.configuration?.parameters ?? {},
    (_: string, config: ParameterConfigurationSpec) =>
      buildParameterValue(config.defaultValue, config),
  );

const isFile = (type: string) =>
  getDefaultEditorForAnthaType(type) === EditorType.FILE ||
  getDefaultEditorForAnthaType(type) === EditorType.MULTI_FILE ||
  getDefaultEditorForAnthaType(getArrayTypeFromAnthaType(type)) === EditorType.FILE;

type ConvertedDefaultFile =
  | FileObject
  | FileObjectLegacy
  | (FileObject | FileObjectLegacy)[]
  | null;

function useBase64ToFileTreeLink() {
  const upload = useUpload();
  return useCallback(
    async function base64ToFileTreeLink(
      value: DirectUploadSingleValueLegacy,
    ): Promise<FileObject | FileObjectLegacy | null> {
      const b64Data = value?.bytes?.bytes;
      if (!b64Data) {
        return null;
      }
      const decodedData = decodeBase64(b64Data);
      if (!decodedData) {
        return null;
      }
      const blob = new Blob([decodedData], {
        type: 'application/octet-stream',
      });
      const filename = sanitizeFilenameForFiletree(value.name.trim());
      const file: File = new File([blob], filename, {
        type: 'application/octet-stream',
      });
      const fileIndexPath = ['parameters', 'v1', filename, uuid()].join(
        '/',
      ) as FiletreePath;
      return upload({
        file: file,
        metadata: {
          indexPath: fileIndexPath,
        },
      });
    },
    [upload],
  );
}

// Generate parameter values for a new element instance, based on the element
function useGenerateParameterValues() {
  const base64ToFileTreeLink = useBase64ToFileTreeLink();
  return useCallback(
    async function generateParameterValues(
      element: GraphQLElement,
    ): Promise<ParameterValueDict> {
      if (!element?.inputs) {
        return {};
      }

      const fileInputs = element.inputs.filter(input => isFile(input.type));

      // Use the default parameters if present
      const defaultParameters = element.defaultParameters;

      if (defaultParameters && fileInputs.length === 0) {
        return defaultParameters;
      }

      // Inputs that are a file or an array of files may have an example embedded as base64.
      // Replace the base64 values with filetree links instead, so that simulations
      // that include it work. (See T2218)
      if (defaultParameters) {
        const convertedParameters: { [name: string]: ConvertedDefaultFile } = {};
        for (const parameter of fileInputs) {
          const defaultValue = defaultParameters[parameter.name];
          if (Array.isArray(defaultValue)) {
            const files = await Promise.all(defaultValue.map(base64ToFileTreeLink));
            const encodedFiles = files.filter(isDefined);
            convertedParameters[parameter.name] = encodedFiles;
            // If there were default values and any of them decoded to a null value, there was a problem.
            if (defaultValue.length > 0 && encodedFiles.length !== defaultValue.length) {
              console.error(
                `One or more default files could not be decoded for parameter ${parameter.name} from ${element.name}`,
              );
            }
          } else {
            const decodedFile = await base64ToFileTreeLink(defaultValue);
            convertedParameters[parameter.name] = decodedFile;
            // If there was a default value but the decoded value is null, there was a problem.
            if (defaultValue && !decodedFile) {
              console.error(
                `Failed to decode default file for parameter ${parameter.name} from ${element.name}`,
              );
              convertedParameters[parameter.name] = null;
            }
          }
        }
        return { ...defaultParameters, ...convertedParameters };
      }

      // Use the hardcoded examples specified for each type
      const parameterTypes = AnthaHardcoded.TypeColors();
      return element.inputs.reduce((result, input) => {
        const match = input.type.match(/(map\[.*?\]|\[\])*.*?([^/]*)$/);
        if (!match) {
          console.error(`Invalid map parameter type ${input.type}`);
          return result;
        }
        const [, prefix, suffix] = match;
        const shortInputType = `${prefix || ''}${suffix}`;
        const colorType = parameterTypes.find(t => t.type === shortInputType);
        result[input.name] = colorType ? colorType.example : undefined;
        return result;
      }, {} as ParameterValueDict);
    },
    [base64ToFileTreeLink],
  );
}

// Parse bundle file

// Parse the GraphQL response into the state of the workflow builder.
export function deserialiseWorkflowResponse(
  workflow: GraphQLWorkflow,
  elementSet: GraphQLElementSet,
): {
  workflowState: WorkflowState;
  errors: string[];
} {
  const bundle = fillLayoutPreferences(workflow.workflow);
  const { elements } = elementSet;
  const errorsToSurface = [];
  // Eventually we'll do the deserialisation in appserver instead
  const {
    elementInstances: clientSideElementInstances,
    errors: elementErrors,
    deletedElementInstances,
  } = deserialiseElements(bundle.Elements.Instances, elements);
  if (elementErrors.length > 0) {
    errorsToSurface.push(elementErrors.join(', '));
  }

  // get all connections, ensure we won't try to use something undefined if it's missing in the workflow
  const allConnections: Connection[] = bundle.Elements.InstancesConnections ?? [];

  // Copied from SimulationService. Should go away once client-side
  // (WorkflowBuilderStateContext) representation of params looks the same as
  // server side. That is, params are inside the element instances.
  const allParameters: BundleParameters = {};
  for (const [name, instance] of Object.entries(bundle.Elements.Instances)) {
    if (deletedElementInstances.includes(instance.TypeName)) {
      continue;
    }
    allParameters[name] = instance.Parameters;
  }

  const { connections, warnings: connectionWarnings } = validateConnections(
    elements,
    clientSideElementInstances,
    allConnections,
  );

  for (const connectionWarning of connectionWarnings) {
    console.warn(connectionWarning);
  }

  const factorisedParameters = getFactorisedParameters(workflow.workflow.Factors);

  const {
    parameters,
    errors: paramErrors,
    warnings: paramWarnings,
  } = validateParameters(allParameters, clientSideElementInstances, connections);
  if (elementErrors.length > 0) {
    errorsToSurface.push(paramErrors.join(', '));
  }
  for (const paramWarning of paramWarnings) {
    console.warn('Warning when parsing element parameters from bundle: ' + paramWarning);
  }

  const isReadonly = isWorkflowReadonly(
    WorkflowEditMode[workflow.editMode],
    workflow.source as unknown as WorkflowEditorType,
  );

  // We don't modify readonly workflows, so we only want to include stages in this view
  // if stages already exist in the workflow (i.e. we won't show for existing workflows
  // that were created before stages were implemented).
  const includeStages = isReadonly ? !!workflow.workflow.Stages : true;

  let stages = !includeStages
    ? []
    : workflow.workflow.Stages && workflow.workflow.Stages.length > 0
    ? workflow.workflow.Stages
    : generateStagesForStagelessWorkflow(clientSideElementInstances, bundle.Config);

  // Fix buggy workflows. These will mostly be those made while multi-device was in development.
  stages = produce(stages, draft => {
    // Ensure elements are assigned to the correct stage.
    assignElementsToStages(clientSideElementInstances, draft, { reset: true });

    // Correct any stages that have no meta.
    draft.forEach((stage, i) => {
      if (i > 0) {
        if (stage.meta.x === undefined) {
          const minElementX = Math.min(
            ...clientSideElementInstances
              .filter(el => stage.elementIds.includes(el.Id))
              .map(el => el.Meta.x),
          );

          stage.meta.x = minElementX;
        }
      } else {
        stage.meta.x = undefined;
      }
    });
  });

  return {
    workflowState: {
      config: bundle.Config,
      InstancesConnections: connections.map(cloneWithUUID),
      elementInstances: clientSideElementInstances,
      parameters,
      stagedParameters: {},
      workflowName: workflow.name,
      editMode: WorkflowEditMode[workflow.editMode],
      source: workflow.source as unknown as WorkflowEditorType,
      authorName: workflow.createdBy.displayName,
      parentWorkflowID: workflow.parentWorkflowID,
      elementSet,
      template: workflow.workflow.Template,
      selectedObjectIds: [],
      erroredObjectIds: [],
      dragDelta: null,
      draggedObjectId: null,
      simulationNotifications: [],
      activePanel: undefined,
      additionalPanel: undefined,
      labwarePreferenceType: 'inputPlates',
      labwarePreferencesAddedOrder: {},
      plateEditorPanelProps: { plateName: undefined, plateNameIndex: undefined },
      elementGroups: workflow.workflow.Groups ?? [],
      stages: stages,
      visibleCanvasArea: undefined,
      centerArea: undefined,
      centerElementId: undefined,
      factors: workflow.workflow.Factors
        ? (workflow.workflow.Factors ?? []).map(cloneWithUUID)
        : null,
      factorisedParameters,
      factorEditing: {
        selectedFactorElement: undefined,
        selectedFactorParameter: undefined,
      },
      mode: 'Build',
      contentSource: workflow.contentSource,
      switchElementParameterValidation: getElementValidationPreference(),
      elementContextError: null,
      isSaving: false,
      plateLayoutEditor: undefined,
      selectedStageId: undefined,
      stageDragDelta: 0,
      dragError: undefined,
      lastNudged: undefined,
      outputPreviewPanelProps: {
        selectedOutputParameterName: undefined,
        selectedPlateName: undefined,
        outputType: undefined,
        entityView: undefined,
      },
      elementInstancePanelTab: ElementDetailsTabs.INPUTS,
    },
    errors: errorsToSurface,
  };
}

/**
 * ensure that layoutPreferences are complete for any devices which
 * have them
 */
function fillLayoutPreferences(bundle: ServerSideBundle) {
  return produce(bundle, draft => {
    draft.Config.configuredDevices = draft.Config.configuredDevices?.map(cd => {
      if (!cd.layoutPreferences) {
        return cd;
      }

      if (!cd.layoutPreferences.inputs) {
        cd.layoutPreferences.inputs = [];
      }
      if (!cd.layoutPreferences.outputs) {
        cd.layoutPreferences.outputs = [];
      }
      if (!cd.layoutPreferences.temporaryLocations) {
        cd.layoutPreferences.temporaryLocations = [];
      }
      if (!cd.layoutPreferences.tipboxes) {
        cd.layoutPreferences.tipboxes = [];
      }
      if (!cd.layoutPreferences.tipwastes) {
        cd.layoutPreferences.tipwastes = [];
      }
      if (!cd.layoutPreferences.plates) {
        cd.layoutPreferences.plates = {};
      }
      return cd;
    });
  });
}

// Part of parsing the bundle on upload
function deserialiseElements(
  elementInstances: { [name: string]: ServerSideElementInstance },
  elements: readonly Element[],
): {
  elementInstances: ElementInstance[];
  errors: string[];
  deletedElementInstances: string[];
} {
  const errors: string[] = [];
  const deletedElementInstances: string[] = [];
  const deserialisedInstances: ElementInstance[] = [];

  const elementsByName = indexBy(elements, 'name');

  let elementInstanceCounter = 0;
  for (const [name, instance] of Object.entries(elementInstances)) {
    const { Meta, TypeName, Id } = instance;
    if (!TypeName) {
      errors.push(`Process has no element: ${JSON.stringify(TypeName)}`);
    }
    const element = elementsByName[TypeName];
    if (element) {
      deserialisedInstances.push({
        // Needed for the UI to find the element for the instance when rendering.
        // Once we use elements from GraphQL, each element instance will automatically
        // always have .element.
        // @ts-ignore
        element,
        name,
        Id: Id || uuid(),
        TypeName,
        Meta: {
          // Lay out the elements in grid if position information is missing
          // (can happen when uploading a workflow obtained from antha-core CLI)
          x: Meta?.x ?? (elementInstanceCounter % 6) * 200,
          y: Meta?.y ?? Math.floor(elementInstanceCounter / 6) * 200,
          annotation: Meta?.annotation,
          errors: Meta?.errors ?? [],
          status: Meta?.status ?? 'neutral',
          /**
           * dirty flag is set to true when any element parameter is changed by user
           */
          dirty: Meta?.dirty ?? false,
          showValidation: Meta?.showValidation ?? false,
          outputs: Meta?.outputs,
        },
      });
      elementInstanceCounter++;
    } else {
      errors.push(
        `The element ${TypeName} referenced in this workflow does not exist anymore.`,
      );
      deletedElementInstances.push(TypeName);
    }
  }
  return {
    elementInstances: deserialisedInstances,
    errors,
    deletedElementInstances,
  };
}

function validateConnections(
  elements: readonly Element[],
  elementInstances: ElementInstance[],
  connections: Connection[],
) {
  const warnings = [];
  const validatedConnections = [];
  const elementsByName = indexBy(elements, 'name');
  const instancesByName = indexBy(elementInstances, 'name');

  for (const connection of connections) {
    const {
      Source: { ElementInstance: sourceInstanceName, ParameterName: sourceParam },
      Target: { ElementInstance: targetInstanceName, ParameterName: targetParam },
    } = connection;

    const sourceInstance = instancesByName[sourceInstanceName];
    if (!sourceInstance) {
      continue;
    }
    const sourceElement = elementsByName[sourceInstance.TypeName];
    if (!sourceElement.outputs.find(param => param.name === sourceParam)) {
      warnings.push(`No port named ${sourceParam} on element ${sourceElement.name}`);
      continue;
    }

    const targetInstance = instancesByName[targetInstanceName];
    if (!targetInstance) {
      continue;
    }
    const targetElement = elementsByName[targetInstance.TypeName];
    if (!targetElement.inputs.find(param => param.name === targetParam)) {
      warnings.push(`No port named ${targetParam} on element ${targetElement.name}`);
      continue;
    }

    validatedConnections.push(connection);
  }

  return {
    connections: validatedConnections,
    warnings,
  };
}

// Validate bundle on upload
function validateParameters(
  parameters: BundleParameters,
  // We keep passing around these random bits representing the Worfklow.
  // We should have a TypeScript type called Workflow and pass that around.
  elementInstances: ElementInstance[],
  connections: Connection[],
): {
  parameters: BundleParameters;
  errors: string[];
  warnings: string[];
} {
  const newParameters: BundleParameters = {};
  const instancesByName = indexBy(elementInstances, 'name');
  const warnings: string[] = [];
  const errors: string[] = [];

  for (const instanceName of Object.keys(parameters)) {
    // For some reason an instance might have no parameters. Happens when importing this simulation
    // for example (instance "Append Liquid Sets"):
    // https://antha.ninja/#/simulation-details/2ADW3NZDH8Z50ND2X0SAVC70M2
    const paramValues = parameters[instanceName] || {};
    const instance = instancesByName[instanceName];
    if (!instance) {
      errors.push(`Parameters refer to non-existent element instance: ${instanceName}`);
      // Stop. If the user is uploading a bundle that is so broken it refers to
      // non-existent elements, that's a problem.
      return { parameters: newParameters, errors, warnings };
    }

    // Things to check:
    // 1) Is there a value for every input the element has
    // 2) Are there any parameter values that assign to ports that
    //    have connections
    // 3) Are there any parameters being set that don't exist in the
    //    element

    const omittedParameters = new Set();
    const inputNames: Set<string> = new Set(instance.element.inputs.map(i => i.name));
    const paramValueNames = new Set(Object.keys(paramValues));

    const connectionTargetNames = connections.reduce((result, conn) => {
      if (conn.Target.ElementInstance === instanceName) {
        result.add(conn.Target.ParameterName);
      }
      return result;
    }, new Set());

    inputNames.forEach(inputName => {
      const hasValue = paramValueNames.has(inputName);
      const hasConnection = connectionTargetNames.has(inputName);

      // Check to see if the parameter has been set both as a connection
      // and as a value.
      if (hasValue && hasConnection) {
        warnings.push(
          [
            'Ignoring parameter with both a value and a connection: ',
            `${instanceName}.${inputName}`,
          ].join(' '),
        );
        omittedParameters.add(inputName);
      }
    });

    paramValueNames.forEach(paramValueName => {
      if (!inputNames.has(paramValueName)) {
        warnings.push(`Ignoring unknown parameter: ${instanceName}.${paramValueName}`);
        omittedParameters.add(paramValueName);
      }

      if (!newParameters[instanceName]) {
        newParameters[instanceName] = {};
      }

      if (!omittedParameters.has(paramValueName)) {
        // If we've made it this far, we trust that this param is legit.
        newParameters[instanceName][paramValueName] = paramValues[paramValueName];
      }
    });
  }
  return { parameters: newParameters, errors, warnings };
}

export function buildWorkflowBuilderStateBundle(
  input: BuildBundleInput,
): Readonly<ServerSideBundle> {
  const parameters = buildValidBundleParameters(input.elementInstances, input.parameters);
  const normalisedLayout = normaliseLayoutPositions(
    input.elementInstances,
    input.groups,
    input.stages ?? [],
  );
  return buildBundle({
    ...input,
    elementInstances: normalisedLayout.elementInstances,
    groups: normalisedLayout.elementGroups,
    stages: normalisedLayout.stages ?? [],
    parameters,
  });
}

function buildValidBundleParameters(
  elementInstances: ElementInstance[],
  parameters: BundleParameters,
) {
  const resultParameters: BundleParameters = {};

  elementInstances.forEach(instance => {
    const newParamValues: ParameterValueDict = (resultParameters[instance.name] = {});

    const instanceParameterValues = parameters[instance.name];
    const allInstanceParameters = instance.element.inputs;

    const parameterConfigMap: Map<string, ParameterConfigurationSpec | null | undefined> =
      new Map(
        allInstanceParameters.map(parameter => [parameter.name, parameter.configuration]),
      );

    for (const parameterName in instanceParameterValues) {
      const parameterValue = instanceParameterValues[parameterName];
      const parameterConfig = parameterConfigMap.get(parameterName);

      newParamValues[parameterName] = buildParameterValue(
        parameterValue,
        parameterConfig,
      );
    }
  });

  return resultParameters;
}

function getFactorisedParameters(factors: FactorItem[] = []) {
  const result: BundleParameters = {};

  for (const factor of factors) {
    /**
     * If there is no path to the factor parameter there is nothing to initialise.
     */
    if (!factor.path) continue;

    const [_, elementInstanceName, parameterName] = factor.path;

    if (!result[elementInstanceName]) {
      result[elementInstanceName] = {};
    }

    result[elementInstanceName][parameterName] = true;
  }

  return result;
}

/**
 * Creates a single stage for worklfows that do not yet have stages defined.
 * All existing elements and devices in the workflow will be assigned to this
 * single stage.
 *
 * @param elementInstances All existing element instances
 * @returns Configured stage
 */
function generateStagesForStagelessWorkflow(
  elementInstances: ElementInstance[],
  config: WorkflowConfig,
): Stage[] {
  return [
    {
      id: '0', // Stage ids are numbered in order in antha-core.
      name: 'Default stage',
      elementIds: elementInstances.map(element => element.Id),
      configuredDevices:
        config.configuredDevices?.map(configuredDevice => configuredDevice.id) ?? [],
      meta: {},
    },
  ];
}

export const LSKEY_SHOW_ELEMENT_VALIDATION = 'show_workflow_builder_element_validation';

export function getElementValidationPreference() {
  const value = localStorage.getItem(LSKEY_SHOW_ELEMENT_VALIDATION);
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return true;
  }
}

// We don't want to allow pasting of elements into exactly the same spot
// so if we detect that the instance name is the same, we'll also shift
// the element over a smidge to make sure they're visually distinct from
// the originals.
const DUPLICATE_INSTANCE_POSITION_OFFSET = 25;

// In some cases, specifically when a user copies and pastes within the
// Workflow builder, we'll have a situation where we will have element
// instances, parameters, and connections with non-unique instance names. In
// order to safely merge two bundles, we have to know that all of the instance
// names are unique between them.
//
// This function takes a bundle and a list of instance names to check for. It
// will replace all references to an existing instance name throughout the
// bundle with a unique name, preserving any connections or parameter values
// that have been set. With this function, you can safely merge two identical
// bundles by de-duping one of them such that they will no longer have any name
// conflicts.
export function makeBundleContentsUnique(
  bundle: ServerSideBundle,
  existingElementNames: Set<string>,
  existingGroupNames: Set<string>,
): ServerSideBundle {
  // If we have no ElementInstances, there should be no connections
  // and no Parameters
  if (
    isFalsyOrEmptyObject(bundle.Elements.Instances) &&
    arrayIsFalsyOrEmpty(bundle.Groups)
  ) {
    return { ...bundle };
  }

  const {
    Elements: { Instances, InstancesConnections },
    Groups,
  } = bundle;

  // Do the shallowest clone of the objects that will accommodate the mutations
  // that may be necessary
  const elementInstances = { ...Instances };
  let connections: Connections = [];
  if (!arrayIsFalsyOrEmpty(InstancesConnections)) {
    // Clone the connection objects so that we can update them without
    // altering the original bundle (dat pass-by-reference tho).
    connections = InstancesConnections.map(conn => {
      return { ...conn };
    });
  }

  const names = new Set(existingElementNames);
  Object.keys(elementInstances).forEach((key: string) => {
    // If this instance name doesn't conflict, then no worries, we can
    // just carry on.
    if (!names.has(key)) {
      return;
    }

    // If we're here, we've got a name conflict that we need to resolve
    const currentName = key;
    let newName = key;
    while (names.has(newName)) {
      newName = getCopyName(newName);
    }
    names.add(newName);
    elementInstances[newName] = {
      ...elementInstances[currentName],
      Meta: {
        annotation: elementInstances[currentName].Meta.annotation,
        x: elementInstances[currentName].Meta.x + DUPLICATE_INSTANCE_POSITION_OFFSET,
        y: elementInstances[currentName].Meta.y + DUPLICATE_INSTANCE_POSITION_OFFSET,
      },
    };
    // We don't deal with the name property here as it should not be present
    // on pasted bundles.
    delete elementInstances[currentName];

    // We're mutating the objects in place, which is fine because we
    // cloned them above
    connections.forEach(conn => {
      if (conn.Source.ElementInstance === currentName) {
        conn.Source.ElementInstance = newName;
      }

      if (conn.Target.ElementInstance === currentName) {
        conn.Target.ElementInstance = newName;
      }
    });
  });

  return {
    ...bundle,
    Elements: {
      Instances: elementInstances,
      InstancesConnections: connections,
    },
    Groups: Groups ? makeElementGroupsUnique(Groups, existingGroupNames) : undefined,
  };
}

function makeElementGroupsUnique(groups: Group[], existingNames: Set<string>) {
  const names = new Set(existingNames);

  return groups.map(group => {
    if (!names.has(group.name)) {
      return group;
    }

    let newName = group.name;

    while (names.has(newName)) {
      newName = getCopyName(newName);
    }

    names.add(newName);

    return {
      ...group,
      name: newName,
      Meta: {
        ...group.Meta,
        x: group.Meta.x + DUPLICATE_INSTANCE_POSITION_OFFSET,
        y: group.Meta.y + DUPLICATE_INSTANCE_POSITION_OFFSET,
      },
    };
  });
}

/**
 * Needed to enable `drop` events. (More at
 * https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API)
 */
export function doPreventDefault(e: React.DragEvent<HTMLDivElement>) {
  e.preventDefault();
}

async function validateWorkflow(apollo: ApolloClient<object>, workflow: GraphQLWorkflow) {
  const configuredDevices = workflow.workflow.Config.configuredDevices ?? [];
  const allDevicesGraphQL = await apollo.query({
    query: QUERY_ALL_DEVICES,
  });
  const allDevices = allDevicesGraphQL.data?.devices ?? [];
  return {
    configHasDeletedDevice: configHasDeletedDevice(allDevices, configuredDevices),
    configHasDeletedDeviceRunConfig: configHasDeletedDeviceRunConfig(
      allDevices,
      configuredDevices,
    ),
    configHasOutdatedDeviceRunConfig: configHasOutdatedDeviceRunConfig(
      allDevices,
      configuredDevices,
    ),
  };
}

function validateWorkflowAndWarnUser(
  apollo: ApolloClient<object>,
  workflow: GraphQLWorkflow,
  snackbarManager: SnackbarManager,
) {
  (async () => {
    if (
      isWorkflowReadonly(
        workflow.editMode as unknown as WorkflowEditMode,
        workflow.source as unknown as WorkflowEditorType,
      )
    ) {
      return;
    }

    const USING_MISSING_DEVICE = `A device in this workflow has been deleted. Use the Settings panel to select a different device.`;
    const USING_MISSING_DEVICE_RUN_CONFIG = `The config of a device in this workflow has been deleted. Select a new layout from the Deck Options panel after opening the Settings panel.`;

    const USING_OUTDATED_DEVICE_RUN_CONFIG = `The config of a device in this workflow is outdated. Please check that the right preferences are selected.`;
    const USING_MISSING_DEVICE_AND_RUN_CONFIG = `A device in this workflow has been deleted, and the config of a device has been deleted, too. Use the Settings panel to resolve these problems.`;

    const {
      configHasDeletedDevice,
      configHasDeletedDeviceRunConfig,
      configHasOutdatedDeviceRunConfig,
    } = await validateWorkflow(apollo, workflow);
    if (
      configHasDeletedDevice ||
      configHasDeletedDeviceRunConfig ||
      configHasOutdatedDeviceRunConfig
    ) {
      let msg;
      if (configHasDeletedDevice && configHasDeletedDeviceRunConfig) {
        msg = USING_MISSING_DEVICE_AND_RUN_CONFIG;
      } else if (configHasDeletedDeviceRunConfig) {
        msg = USING_MISSING_DEVICE_RUN_CONFIG;
      } else if (configHasOutdatedDeviceRunConfig) {
        msg = USING_OUTDATED_DEVICE_RUN_CONFIG;
      } else {
        msg = USING_MISSING_DEVICE;
      }
      snackbarManager.showError(msg);
    }
  })();
}
export function useValidateWorkflowAndWarnUser() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, validateWorkflowAndWarnUser);
}

export function useManualDeviceDefault() {
  /**
   * QUERY_ALL_DEVICES is used during workflow validation so it gets cached.
   * Hence, no need in dedicated query for manual device.
   */
  const { data } = useQuery(QUERY_ALL_DEVICES);
  const setManualAsDefault = useManualAsDefault(data?.devices);

  return useCallback(
    (config: WorkflowState['config']) => {
      const someDeviceSelected = !configHasNoDevices(config);

      if (isDataOnly(config) || someDeviceSelected) return;

      setManualAsDefault();
    },
    [setManualAsDefault],
  );
}

function useManualAsDefault(devices: AllDevicesQuery['devices'] | undefined) {
  const dispatch = useWorkflowBuilderDispatch();

  return useCallback(() => {
    const manualDevice = devices?.find(d => d.model.anthaLangDeviceClass === 'Manual');
    if (manualDevice) {
      dispatch({
        type: 'setSelectedDevices',
        payload: buildConfiguredDevice([manualDevice]).configuredDevices,
      });
    }
  }, [devices, dispatch]);
}

/**
 * Given partialElementsBundle of ServerSideElements, and the list of elementInstances that are in the
 * required org, this will fill out the partialElementsBundle with any missing values that are specified
 * in element configuratons.
 *
 * It will also update the instance names in the partialElementsBundle to conform to our standardised
 * sequential naming (e.g "Aliquot 1", "Aliquot 2"...)
 *
 * If an element name in the partialElementsBundle is not found in the elements, an error will be thrown.
 */
export function useMergeElementBundle() {
  const createElementInstance = useCreateElementInstance();

  const mergeElements = async (
    partialElementsBundle: ServerSideElements,
    elements: readonly GraphQLElement[],
  ): Promise<ServerSideElements> => {
    const updatedElementsBundle = { ...partialElementsBundle };

    // We store created instances to re-feed into createElementInstance().
    // This allows us to correctly create sequential names in cases where there
    // may be more than one instance.
    const createdInstances: ElementInstance[] = [];

    for (const instanceName in partialElementsBundle.Instances) {
      const currentInstance = partialElementsBundle.Instances[instanceName];
      const graphQlElement = elements.find(
        element => element.name === currentInstance.TypeName,
      );
      if (!graphQlElement) {
        throw new Error(
          `Element ${currentInstance.TypeName} not found in default element set`,
        );
      }
      const newInstance = await createElementInstance(graphQlElement, createdInstances, {
        x: 0,
        y: 0,
      });

      createdInstances.push(newInstance.instance);

      const mergedParameters = { ...newInstance.params, ...currentInstance.Parameters };
      updatedElementsBundle.Instances[instanceName].Parameters = mergedParameters;

      // Re-assign with the returned instance name and delete the old entry.
      updatedElementsBundle.Instances[newInstance.instance.name] =
        updatedElementsBundle.Instances[instanceName];
      delete updatedElementsBundle.Instances[instanceName];
    }
    return updatedElementsBundle;
  };

  return mergeElements;
}

type Mutable<T> = {
  -readonly [k in keyof T]: Mutable<T[k]>;
};

/**
 * When we first load a workflow, we use the bundle received from the server to prepopulate
 * the last-saved workflow state in the builder. But the bundle from the server contains some
 * properties that we don't send to the server when saving a change. This can cause the
 * auto-save to be triggered immediately after loading the workflow. This function fixes the
 * discrepencies between the intial workflow and the version we save.
 */
export const removeUnsavedDataFromServerBundle = produce((base: ServerSideBundle) => {
  const draft = base as Mutable<ServerSideBundle>;

  delete draft.Design;

  draft.Factors = draft.Factors ?? undefined;

  for (const key of Object.keys(draft.Elements.Instances)) {
    draft.Elements.Instances[key].Meta = pick(
      draft.Elements.Instances[key].Meta,
      'x',
      'y',
      'annotation',
    );
  }

  draft.Stages?.forEach(stage => {
    delete stage.meta.showAsInvalid;
  });
});

export function equivalentBundles(
  bundleA: Readonly<ServerSideBundle>,
  bundleB: Readonly<ServerSideBundle>,
): boolean {
  const fieldsToCheck: (keyof ServerSideBundle)[] = [
    'Config',
    'Elements',
    'Factors',
    'Groups',
    'Meta',
    'SchemaVersion',
    'WorkflowId',
    'elementSetId',
    'FeatureToggles',
    'Stages',
  ];
  return isEqual(pick(bundleA, fieldsToCheck), pick(bundleB, fieldsToCheck));
}
