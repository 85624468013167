import React, { useContext, useMemo } from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import { QuadraticBezierCurve } from 'common/ui/components/simulation-details/mix/edgeRouting';
import { ArrowHeadContext } from 'common/ui/components/simulation-details/mix/EdgesSvgOverlay';
import { Edge } from 'common/ui/components/simulation-details/mix/MixState';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export const ADJACENT_WELL_AVG_LENGTH_SQUARED = 1000;

const NO_MOVEMENT_ARC_RADIUS = 5;

type Props = {
  edge: Edge;
  curve: QuadraticBezierCurve;
  arrowAtEnd: boolean;
  pathLengthSquared: number;
};

/**
 * An arrow from one place on the deck to another. For move_plate instructions this will
 * be the center of one deck position to another. Otherwise the edge will be from the
 * center of a well on a plate to another well (possibly on a different plate).
 */
export default function EdgeSvg({ edge, curve, arrowAtEnd, pathLengthSquared }: Props) {
  const classes = useStyles();

  const getArrowURL = useContext(ArrowHeadContext);

  // We can use the path length to determine the right fontSize for the label,
  // so that short paths (adjacent wells) won't have any text cropped
  const isShortPath = pathLengthSquared < ADJACENT_WELL_AVG_LENGTH_SQUARED;

  const svgPathDef = useMemo(() => {
    // If the tip has aspirated from and dispensed to the same location, then show a
    // near-complete arc with an arrow at the end.
    if (curve.start.x === curve.end.x && curve.start.y === curve.end.y) {
      return [
        // Move slightly to the left of the liquid transfer start
        `M ${curve.start.x - 2} ${curve.start.y}`,
        // Add a tiny line to set the angle of the arrowhead. Without this, the arrowhead
        // will be the angle of the start the arc, which obscures half the arrowhead.
        `l -0.0012 0.001`,
        // Render an arc of equal radii. The other parameters are
        `a ${NO_MOVEMENT_ARC_RADIUS} ${NO_MOVEMENT_ARC_RADIUS}`,
        '0', // Angle of ellipse (irrelevant since this is a circle)
        '1', // Show the large arc of the circle, not the small
        '0', // Anticlockwise (this means the circle is underneath the start point)
        `4 0`, // End coord, 4 pixels to the right of we started
      ].join(' ');
    }
    return `M ${curve.start.x} ${curve.start.y} Q ${curve.control.x} ${curve.control.y} ${curve.end.x} ${curve.end.y}`;
  }, [curve]);

  return (
    <path
      className={cx({
        [classes.moveLabwareEdge]: edge.type === 'move_plate',
        [classes.liquidTransferEdge]: edge.type === 'liquid_transfer',
        [classes.liquidDispenseEdge]: edge.type === 'liquid_dispense',
        [classes.filtrationEdge]: edge.type === 'filtration',
      })}
      fill="none"
      d={svgPathDef}
      // "Normal" case - edge is pointing right. Put arrow at the end.
      markerEnd={arrowAtEnd ? getArrowURL(edge, isShortPath, false) : undefined}
      // Edge is pointing left. Draw the edge from left to right, but
      // put the arrow at its start.
      markerStart={arrowAtEnd ? undefined : getArrowURL(edge, isShortPath, true)}
    />
  );
}

const useStyles = makeStylesHook({
  liquidTransferEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 1,
  },
  moveLabwareEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 3,
  },
  liquidDispenseEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 1,
    // Show liquid dispenses (which are part of multidispense transfers) as
    // dashed arrows.
    strokeDasharray: '8 4',
  },
  filtrationEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 2,
    // Show filtration (ie liquid dropping out of a robocolumn) as dotted
    // arrows.
    strokeDasharray: '2 4',
  },
});
