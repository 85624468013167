import React from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  CERTUS_FLEX_ANTHA_LANG_DEVICE_CLASS,
  DRAGONFLY_ANTHA_LANG_DEVICE_CLASS,
  FORMULATRIX_MANTIS_ANTHA_LANG_DEVICE_CLASS,
  FORMULATRIX_TEMPEST_ANTHA_LANG_DEVICE_CLASS,
  LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES,
} from 'common/types/bundle';
import Popover from 'common/ui/components/Popover';

type Props = {
  anthaLangDeviceClass: string;
};

function DeviceIntercomTooltip({ anthaLangDeviceClass }: Props) {
  const title = getTooltipText(anthaLangDeviceClass);

  if (!title) {
    return null;
  }
  return (
    <Popover title={title}>
      <StyledHelpIcon />
    </Popover>
  );
}

function getTooltipText(anthaLangDeviceClass: string) {
  const intercomUrl = DEVICE_MODEL_TO_INTERCOM_LINK[anthaLangDeviceClass];

  if (!intercomUrl) {
    return null;
  }

  return (
    <Typography color={theme => theme.palette.text.primary}>
      For more information on how to run this stage, please see this{' '}
      <StyledLink component="a" href={intercomUrl} target="_blank" rel="noreferrer">
        Help Center article
        <LinkIcon />
      </StyledLink>
    </Typography>
  );
}

const StyledHelpIcon = styled(HelpIcon)({
  height: 12,
});

const StyledLink = styled(Typography<'a'>)({
  display: 'inline-flex',
  alignItems: 'center',
});

const LinkIcon = styled(OpenInNew)({
  fontSize: '16px',
});

const baseUrl = 'https://intercom.help/antha/en/articles/';

const MANTIS_INTERCOM_URL = baseUrl + '8820243-run-an-execution-on-a-formulatrix-mantis';
const TEMPEST_INTERCOM_URL =
  baseUrl + '9335290-run-an-execution-on-a-formulatrix-tempest';
const ECHO_INTERCOM_URL =
  baseUrl + '6845394-run-an-execution-on-the-echo-acoustic-dispenser';
const DRAGONFLY_INTERCOM_URL =
  baseUrl + '5508126-run-an-execution-on-a-dragonfly-discovery#h_2c7fb484cd';
const FLEX_INTERCOM_URL = baseUrl + '6839183-run-an-execution-on-gyger-certus-flex';

const DEVICE_MODEL_TO_INTERCOM_LINK: Record<string, string> = {
  [FORMULATRIX_MANTIS_ANTHA_LANG_DEVICE_CLASS]: MANTIS_INTERCOM_URL,
  [FORMULATRIX_TEMPEST_ANTHA_LANG_DEVICE_CLASS]: TEMPEST_INTERCOM_URL,
  [DRAGONFLY_ANTHA_LANG_DEVICE_CLASS]: DRAGONFLY_INTERCOM_URL,
  [LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES[0]]: ECHO_INTERCOM_URL,
  [LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES[1]]: ECHO_INTERCOM_URL,
  [LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES[2]]: ECHO_INTERCOM_URL,
  [LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES[3]]: ECHO_INTERCOM_URL,
  [LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES[4]]: ECHO_INTERCOM_URL,
  [LABCYTE_ECHO_ANTHA_LANG_DEVICE_CLASSES[5]]: ECHO_INTERCOM_URL,
  [CERTUS_FLEX_ANTHA_LANG_DEVICE_CLASS]: FLEX_INTERCOM_URL,
};

export default DeviceIntercomTooltip;
