import React, { useMemo } from 'react';

import Download from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { DispenseChecklist } from 'client/app/api/ChecklistApi';
import { useDownloadRemoteFileFromPath } from 'client/app/api/filetree';
import Task, {
  TaskStatus,
} from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/Task';
import TaskChecklist from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/TaskChecklist';
import {
  getMetadataFileName,
  getMetadataFilesForStage,
} from 'client/app/apps/execution-details/helpers';
import { ExecutionStage, MetadataFile } from 'client/app/apps/execution-details/types';
import FileExtensionIcon from 'client/app/icons/FileExtensionIcon';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';

type Props = {
  stage: ExecutionStage;
  checklist: DispenseChecklist | undefined;
  showChecklist: boolean;
};

export default function Tasks({ stage, checklist, showChecklist }: Props) {
  const { showConfigs, showLogs, configs, logs } = useFiles(stage);

  return (
    <TasksArea>
      {showConfigs && (
        <Stack>
          <Typography variant="subtitle1" color="black">
            Configuration
          </Typography>
          {configs.map(config => (
            <MetadataFileRow key={config.name} file={config} />
          ))}
        </Stack>
      )}
      {showLogs && (
        <Stack>
          <Typography variant="subtitle1" color="black">
            Logs
          </Typography>
          {logs.map(log => (
            <MetadataFileRow key={log.name} file={log} />
          ))}
        </Stack>
      )}

      {showChecklist && checklist ? (
        <>
          <SubtitleContainer direction="row" alignItems="center" gap={3}>
            <Typography variant="subtitle1" color="black" flexGrow={1}>
              {stage.tasks[0].simulationTask?.name}
            </Typography>
            <TaskStatus task={stage.tasks[0]} />
          </SubtitleContainer>
          <TaskChecklist checklist={checklist} />
        </>
      ) : (
        <>
          <SubtitleContainer>
            <Typography variant="subtitle1" color="black">
              Tasks
            </Typography>
          </SubtitleContainer>
          {stage.tasks.map(task => (
            <Task key={task.id} task={task} />
          ))}
        </>
      )}
    </TasksArea>
  );
}

const MetadataFileRow = ({ file }: { file: MetadataFile }) => {
  const downloadRemoteFileFromPath = useDownloadRemoteFileFromPath();
  const formattedName = getMetadataFileName(file);
  return (
    <FileRow key={file.name}>
      <FileExtensionIcon fileName={file.name} />
      <FileName variant="body1" title={formattedName}>
        {formattedName}
      </FileName>
      <Tooltip title="Download">
        <IconButton
          color="inherit"
          icon={<Download />}
          onClick={() => downloadRemoteFileFromPath(file.filetreeLink, formattedName)}
        />
      </Tooltip>
    </FileRow>
  );
};

function useFiles(executionStage: ExecutionStage) {
  return useMemo(() => {
    const files = getMetadataFilesForStage(executionStage);

    const logs: MetadataFile[] = [];
    const configs: MetadataFile[] = [];

    files.forEach(file => {
      switch (file.fileType) {
        case 'DEVICE_CONFIG': {
          configs.push(file);
          break;
        }
        case 'DEVICE_LOGS': {
          logs.push(file);
          break;
        }
        default:
          break;
      }
    });

    const showConfigs = configs.length > 0;
    const showLogs = logs.length > 0;

    return { showConfigs, showLogs, configs, logs };
  }, [executionStage]);
}

//#region Styles
const SubtitleContainer = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  borderBottomColor: Colors.GREY_20,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
}));

const TasksArea = styled('div')(({ theme }) => ({
  gridArea: 'tasks',

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  margin: theme.spacing(4, 0),
  padding: theme.spacing(4, 5),
  backgroundColor: Colors.GREY_5,
}));

const FileRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const FileName = styled(Typography)({
  flexGrow: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

//#endregion
