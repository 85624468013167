import { useEffect, useState } from 'react';

import * as FilesApi from 'client/app/api/FilesApi';
import { concatURL } from 'common/lib/strings';
import { Coordinates2, PlateContentsMatrix, WellType } from 'common/types/mix';

type Coordinates3OptionalZ = Coordinates2 & { z_mm?: number };

export type PlateInfo = {
  name: string;
  plate_type: string;
  well_contents: PlateContentsMatrix;
  well_summary: {
    rows: number;
    columns: number;
    well_type: WellType;
    well_dimensions: Coordinates3OptionalZ;
    well_start: Coordinates3OptionalZ;
    well_offset: Coordinates2;
  };
};

type DispenseChecklistDetails = {
  source_plates: PlateInfo[];
  destination_plates: PlateInfo[];
  dispense_list: string;
}[];

export type DispenseChecklist = {
  kind: 'dispenseListKind';
  dispense_list_steps: DispenseChecklistDetails;
};
export type ChecklistJSON = { checklist: DispenseChecklist; version: string };

export function useChecklist(simulationTaskFiletreeLink: string | null | undefined) {
  const [checklistJSON, setChecklistJSON] = useState<ChecklistJSON>();
  const fetchJsonFileContents = FilesApi.useFetchJsonFileContents();

  useEffect(() => {
    (async () => {
      try {
        if (simulationTaskFiletreeLink) {
          const checklistFile = await fetchJsonFileContents(
            concatURL(simulationTaskFiletreeLink, 'checklist.json'),
          );
          setChecklistJSON(checklistFile);
        }
      } catch (e) {
        // Checklist file does not exist
        console.error(e);
      }
    })();
  }, [fetchJsonFileContents, simulationTaskFiletreeLink]);

  return checklistJSON?.checklist;
}
