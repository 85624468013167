const tutorials = [
  // Core Elements
  '🎓 Get Started: Learn the Core Elements',
  '🔬 Examples: Exploring Basic Quantification Assays',
  '🔦 Element Spotlight: Using Append',
  '🔦 Element Spotlight: Using Make Mixtures',
  '🔦 Element Spotlight: Using Mix Onto',
  '🔦 Element Spotlight: Using Synchronise',
  // Robocolumns
  '🎓 Get Started: Introduction to Robocolumns',
  '🏫 Onboarding Resource: Learn Robocolumns',
  // Robocolumns DOE
  '🎓 Get Started: Introduction to Robocolumns DOE',
  '🏫 Onboarding Resource: Robocolumns DOE Workshop',
  // DOE
  '🎓 Get Started: Design of Experiments on Synthace',
  '🎓 Get Started: Using DOE with dispensers',
  '🏫 Onboarding Resource: DOE Workshop - Creating DOE workflows and designs',
  '🔦 Element Spotlight: Calculate Stock Concentrations',
  // ELISA
  '🎓 Get Started: Introduction to ELISA',
  '🔬 Examples: Sandwich ELISA',
  // Filter Plates
  '🎓 Get Started: Introduction to Filter Plates',
  // Dev
  // TODO: Remove these when we can push the real tutorials to dev.
  'Capturing multi-step processes',
  'Core Elements Preloaded Workflow Examples',
  'Basic Quantification Assay Preloaded Workflow Examples',
  'RoboColumns Preloaded Workflow Examples',
  'Advanced Core Elements Preloaded Workflows',
];

export const tutorialsOrdering = Object.fromEntries(
  tutorials.map((name, i) => [name, i]),
);
