import React from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { usePlatesWithCompatibility } from 'client/app/api/PlateTypesApi';
import useStageConfiguredDevice from 'client/app/apps/plate-library/useStageConfiguredDevice';
import Plates from 'client/app/components/Labware/components/Plates';
import { LabwareInfoSection } from 'client/app/components/Labware/types';
import getPlateInfo from 'client/app/components/PlatePrep/getPlateInfo';
import WellsList from 'client/app/components/PlatePrep/WellsList';
import { PlateTypeWithCompatibility } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import LinearProgress from 'common/ui/components/LinearProgress';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';

type Props = {
  plates: PlateState[];
  // Well location under cursor. Synchronised between the sidebar and the plate view.
  highlightedWellLocation: WellLocationOnDeckItem | null;
  selectedPlate: PlateState | null;
  liquidColors: LiquidColors;
  onPlateClick: (clickedPlateIdInSidebar: string) => void;
  onWellMouseEnter: (loc: WellLocationOnDeckItem) => void;
};

/**
 * Sidebar with textual plate summaries
 */
export default function PlatesSidebar({
  plates,
  selectedPlate,
  highlightedWellLocation,
  liquidColors,
  onPlateClick,
  onWellMouseEnter,
}: Props) {
  const isPlateFilteringByDeviceEnabled = useFeatureToggle('PLATE_FILTERING_BY_DEVICE');
  const deviceId = useStageConfiguredDevice(false);
  const [plateCompatibilities, loading] = usePlatesWithCompatibility(
    isPlateFilteringByDeviceEnabled && deviceId ? [deviceId as DeviceId] : undefined,
  );

  return (
    <Sidebar>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <Typography variant="h5" color="textPrimary">
            Plates
          </Typography>
          <Section>
            <Plates
              section={{
                sectionHeader: '',
                sectionItems: plates.map(plate => {
                  const plateCompatibility = plateCompatibilities.find(
                    plateCompatibility => plateCompatibility.plate.type === plate.type,
                  )?.compatibility;

                  return {
                    id: plate.id,
                    clickable: true,
                    selected: plate.id === selectedPlate?.id,

                    primaryLabel: plate.name,
                    secondaryLabels: [],
                    tooltipContent: getPlateInfo(plate),
                    compatibility: isPlateFilteringByDeviceEnabled
                      ? getPlateCompatibility(plateCompatibility)
                      : undefined,
                  };
                }),
              }}
              onClick={id => {
                if (id) onPlateClick(id);
              }}
            />
          </Section>
          <Divider sx={{ mr: 5 }} />
          <Typography variant="h5" color="textPrimary">
            Liquids
          </Typography>
          <Section>
            {selectedPlate && (
              <WellsList
                highlightedWellLocation={highlightedWellLocation}
                plate={selectedPlate}
                liquidColors={liquidColors}
                onWellMouseEnter={onWellMouseEnter}
              />
            )}
          </Section>
        </>
      )}
    </Sidebar>
  );
}

type LabwareInfoSectionPlateCompatibility = Exclude<
  LabwareInfoSection['sectionItems'][number]['compatibility'],
  undefined
>;

function getPlateCompatibility(
  plateCompatibility: PlateTypeWithCompatibility['compatibility'] | undefined,
): LabwareInfoSectionPlateCompatibility {
  return plateCompatibility
    ? {
        value: plateCompatibility.accessibleDeviceCompatibilityMessage
          ? 'incompatibleWithAccessibleDevice'
          : plateCompatibility.output
          ? 'compatible'
          : 'incompatible',
        accessibleDeviceCompatibilityMessage:
          plateCompatibility.accessibleDeviceCompatibilityMessage,
      }
    : {
        value: 'incompatible',
        accessibleDeviceCompatibilityMessage: null,
      };
}

const Sidebar = styled(Stack)(({ theme }) => ({
  flex: 1,
  minWidth: 272,
  padding: theme.spacing(6, 0, 6, 5),
  gap: theme.spacing(5),

  backgroundColor: Colors.GREY_10,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const Section = styled('section')(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  scrollbarGutter: 'stable',
  paddingRight: theme.spacing(2),
}));
