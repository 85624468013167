import React, { useMemo } from 'react';

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LiquidIdentifierList from 'client/app/apps/workflow-builder/output-preview/LiquidIdentifierList';
import NoPreview from 'client/app/components/ElementPlumber/ElementOutputs/components/NoPreview';
import PlatePreview from 'client/app/components/ElementPlumber/ElementOutputs/components/PlatePreview';
import {
  BlueChip,
  ChipRow,
  ExpandButton,
  VisibilityIcon,
} from 'client/app/components/ElementPlumber/ElementOutputs/components/styles';
import { VisualisableOutputType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import { useInputLiquidNamesAndGroups } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import { Liquid } from 'common/types/bundle';

type Props = {
  elementId: string;
  parameterName: string;
  type: VisualisableOutputType;
  value: Liquid[];
  entity?: OutputEntity;
  withDropdown?: boolean;
  onClick?: (entity?: OutputEntity) => void;
};

export default function LiquidsOutput({
  elementId,
  parameterName,
  type: outputType,
  value: liquids,
  entity: activeEntity,
  withDropdown = false,
  onClick: handleClick,
}: Props) {
  const dispatch = useWorkflowBuilderDispatch();

  const handleOpenOutputPreview = () => {
    dispatch({
      type: 'openOutputPreviewPanel',
      payload: {
        selectedElementId: elementId,
        selectedOutputParameterName: parameterName,
        outputType,
        entityView: activeEntity ?? 'plate',
      },
    });
  };

  const inputLiquidNamesAndGroups = useInputLiquidNamesAndGroups(liquids);

  const plateSet = useMemo(
    () => new Set(liquids.map(liquid => liquid.position?.plateName).filter(Boolean)),
    [liquids],
  );

  const outputCopy = outputType === 'FilterMatrix' ? 'roboColumn' : 'liquid';
  const outputCount = liquids.length;

  if (outputCount === 0) {
    return <NoPreview label="No output data" />;
  }

  const toggleEntity = (entity: OutputEntity) => {
    handleClick?.(entity !== activeEntity ? entity : undefined);
  };

  return (
    <Stack>
      <Stack direction="row" gap={3} padding={3} alignItems="center">
        <VisibilityIcon />
        <ChipRow>
          <BlueChip
            active={activeEntity === 'liquid'}
            onClick={() => toggleEntity('liquid')}
          >
            <Typography variant="caption" textTransform="capitalize" fontWeight={500}>
              {pluralize(outputCount, outputCopy)}
            </Typography>
          </BlueChip>
          {plateSet.size > 0 && (
            <BlueChip
              active={activeEntity === 'plate'}
              onClick={() => toggleEntity('plate')}
            >
              <Typography variant="caption" textTransform="capitalize" fontWeight={500}>
                {pluralize(plateSet.size, 'plate')}
              </Typography>
            </BlueChip>
          )}
          {withDropdown && activeEntity && (
            <ExpandButton
              icon={<OpenInNewRoundedIcon />}
              size="xsmall"
              onClick={handleOpenOutputPreview}
            />
          )}
        </ChipRow>
      </Stack>
      {withDropdown && activeEntity && (
        <>
          <Divider />
          <Stack width={284} maxHeight={240} overflow="hidden auto">
            {activeEntity === 'liquid' && (
              <LiquidIdentifierList liquids={inputLiquidNamesAndGroups} />
            )}
            {activeEntity === 'plate' && (
              <PlatePreview
                elementId={elementId}
                parameterName={parameterName}
                type={outputType}
              />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
