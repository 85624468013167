import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import clamp from 'lodash/clamp';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import { hasParallelTransferStage } from 'common/lib/mix';
import { MixPreviewStages } from 'common/types/mixPreview';
import KeyPoints from 'common/ui/components/simulation-details/StepSlider/components/KeyPoints';
import ParallelTransferStageSlider from 'common/ui/components/simulation-details/StepSlider/components/ParallelTransferStageSlider';
import StageBreakpoint from 'common/ui/components/simulation-details/StepSlider/components/StageBreakpoint';
import {
  Slider,
  SliderContainer,
  SliderCursor,
  SliderCursorInner,
  SliderProgress,
} from 'common/ui/components/simulation-details/StepSlider/components/styles';
import {
  getAdjustedStepWidth,
  getKeyPointColor,
  SLIDER_CURSOR_WIDTH,
  THROTTLE_TIMEOUT,
} from 'common/ui/components/simulation-details/StepSlider/helpers';
import { Props as StepSliderProps } from 'common/ui/components/simulation-details/StepSlider/StepSlider';
import useDebounce from 'common/ui/hooks/useDebounce';
import useThrottle from 'common/ui/hooks/useThrottle';
import { isLeftMouseClick } from 'common/ui/lib/ClickRecognizer';

type Props = Pick<
  StepSliderProps,
  | 'currentStage'
  | 'appliedSteps'
  | 'onStageChange'
  | 'onStepChange'
  | 'keyPoints'
  | 'stages'
  | 'stageDetails'
>;

/**
 * Draggable part of the StepSlider
 */
export default function StepSliderBar({
  currentStage,
  appliedSteps,
  keyPoints,
  stages,
  stageDetails,
  onStageChange,
  onStepChange,
}: Props) {
  const slider = useSliderUpdates(
    currentStage,
    appliedSteps,
    stages,
    onStageChange,
    onStepChange,
  );
  const { isDragging, ...pointerEvents } = useSliderDrag(slider.handlers);

  const { setDragPositionToAppliedStepPosition } = slider.handlers;
  useLayoutEffect(() => {
    /**
     * This effect is necessary for cases:
     * - initial page load where ?step=X query string parameter is used to set the appliedSteps
     * - control buttons are used to navigate between simulation steps
     * - slider cursor position is changed and the number of applied steps is re-calculated for this position
     */
    setDragPositionToAppliedStepPosition(isDragging);
  }, [isDragging, setDragPositionToAppliedStepPosition]);

  const showStages = useMemo(
    /**
     * Show stages if there are more stages then 1 OR
     * if simulation starts with a simulation stage having parallel transfer stages
     */
    () => stages.length > 1 || stages[0].some(hasParallelTransferStage),
    [stages],
  );
  const currentKeyPoint = keyPoints[currentStage]?.find(
    point => point.step === appliedSteps,
  );
  const highlightColor = currentKeyPoint && getKeyPointColor(currentKeyPoint);

  const jumpToStageStart = useCallback(
    (stage: number) => {
      onStageChange(stage);
      onStepChange(stage > 0 ? 1 : 0);
    },
    [onStageChange, onStepChange],
  );

  return (
    <SliderContainer>
      <Slider ref={slider.ref} dragging={isDragging} {...pointerEvents}>
        <KeyPoints
          currentStage={currentStage}
          currentKeyPoint={currentKeyPoint}
          keyPoints={keyPoints}
          stages={stages}
          sliderWidth={slider.width}
        />
        <SliderCursor highlightColor={highlightColor} style={{ left: slider.position }}>
          <SliderCursorInner />
        </SliderCursor>
        <SliderProgress style={{ width: slider.position }} />
      </Slider>
      {showStages &&
        stages.map((_, stageIndex) => {
          const showAdditionalSlider = stages[stageIndex].some(hasParallelTransferStage);

          return (
            <StageBreakpoint
              key={stageIndex}
              position={(stageIndex * slider.width) / stages.length}
              index={stageIndex}
              showIndex={stages.length > 1}
              showPopperArrow={showAdditionalSlider}
              details={stageDetails?.[stageIndex]}
              activeStage={currentStage}
              popperContents={
                showAdditionalSlider && (
                  <ParallelTransferStageSlider
                    steps={stages[stageIndex]}
                    appliedSteps={stageIndex === currentStage ? appliedSteps : 0}
                    onStepChange={step => {
                      if (stageIndex !== currentStage) {
                        onStageChange(stageIndex);
                      }
                      onStepChange(step);
                    }}
                  />
                )
              }
              onClick={jumpToStageStart}
            />
          );
        })}
    </SliderContainer>
  );
}

function useSliderUpdates(
  currentStage: number,
  appliedSteps: number,
  stages: MixPreviewStages,
  onStageChange: StepSliderProps['onStageChange'],
  onStepChange: StepSliderProps['onStepChange'],
) {
  /**
   * The 1st stage of a Simulation starts with a zero-step (no steps/actions applied).
   * All subsequent stages should start with step #1 because the beginning of each
   * intermediate stage is a manual movement of plates onto the next stage area.
   */
  const indexOfInitialStep = currentStage > 0 ? 1 : 0;
  const [position, setPosition] = useState(0);

  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  /**
   * This callback initiates a resize of an element within the ResizeObserver resize loop.
   * This leads to undelivered notifications error in ResizeObserver.
   *
   * To prevent this error we debounce the callback with a 0 sec timeout making sure
   * it is run after all microtasks scheduled by the ResizeObserver.
   * https://github.com/juggle/resize-observer/blob/v3/src/utils/queueMicroTask.ts
   *
   * For more reference please visit:
   * https://github.com/juggle/resize-observer#resize-loop-detection
   */
  const onResize = useDebounce(size => size.width && setWidth(size.width), 0);
  const { ref: observerRef } = useResizeObserver({ onResize });
  const mergedRef = mergeRefs([observerRef, elementRef]);

  const stageCount = stages.length;
  const stageWidth = width / stageCount;
  const stepWidth = useMemo(
    () => getAdjustedStepWidth(currentStage, stages, width),
    [currentStage, stages, width],
  );
  const currentStageStepPosition =
    currentStage * stageWidth +
    appliedSteps * stepWidth -
    /**
     * The 1st applied step of an intermediate stage is a movement of labware
     * onto the next stage area. We want the KeyPoint of this step to meet borders
     * with the StageBreakpoint and for this we have to shift all step positions
     * by width of one step skipping the zero-step.
     */
    indexOfInitialStep * stepWidth;

  const updateDragPosition = (pageX: number) => {
    const sliderElement = elementRef.current;

    if (!sliderElement) return;

    const slider = sliderElement.getBoundingClientRect();
    const dragPos = clamp(
      pageX - slider.x - SLIDER_CURSOR_WIDTH / 2,
      0,
      width - SLIDER_CURSOR_WIDTH,
    );
    setPosition(dragPos);
  };

  const setCursorToDragPosition = (position: number) => {
    const newStage = clamp(Math.floor(position / stageWidth), 0, stageCount - 1);
    const newMaxStep = stages[newStage].length;
    const newStepWidth = getAdjustedStepWidth(newStage, stages, width);
    const newStep = clamp(
      indexOfInitialStep + Math.round((position - newStage * stageWidth) / newStepWidth),
      indexOfInitialStep,
      newMaxStep,
    );

    onStageChange(newStage);
    onStepChange(newStep);
  };
  const setDragPositionToAppliedStepPosition = useCallback(
    (isDragging: boolean) => {
      if (!isDragging) {
        setPosition(currentStageStepPosition);
      }
    },
    [currentStageStepPosition],
  );

  const updateCursorOnDrag = useThrottle(() => setCursorToDragPosition(position), 100);
  const updateCursorOnWheel = (deltaSign: -1 | 1) => {
    const newPosition = clamp(position + deltaSign * stepWidth, 0, width);
    setCursorToDragPosition(newPosition);
  };

  return {
    ref: mergedRef,
    position,
    width,
    handlers: {
      setDragPositionToAppliedStepPosition,
      updateCursorOnDrag,
      updateCursorOnWheel,
      updateDragPosition,
    },
  };
}

type SliderDragHandlers = Pick<
  ReturnType<typeof useSliderUpdates>['handlers'],
  'updateCursorOnDrag' | 'updateCursorOnWheel' | 'updateDragPosition'
>;

export function useSliderDrag(slider: SliderDragHandlers) {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onPointerDown = (event: React.PointerEvent<HTMLElement>) => {
    event.stopPropagation();

    if (isLeftMouseClick(event)) {
      event.currentTarget.setPointerCapture(event.pointerId);
      slider.updateDragPosition(event.pageX);
      setIsDragging(true);
    }
  };
  const onPointerMove = useThrottle((event: React.PointerEvent<HTMLElement>) => {
    event.stopPropagation();

    if (isDragging) {
      slider.updateDragPosition(event.pageX);
      slider.updateCursorOnDrag();
    }
  }, THROTTLE_TIMEOUT);
  const onPointerUp = (event: React.PointerEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsDragging(false);
    slider.updateCursorOnDrag();
  };
  const onWheel = useThrottle((event: React.WheelEvent) => {
    if (!isDragging) {
      const deltaSign = Math.sign(event.deltaY) as -1 | 1;
      slider.updateCursorOnWheel(deltaSign);
    }
  }, THROTTLE_TIMEOUT);

  return {
    isDragging,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onWheel,
  };
}
