import { ExecutionStage } from 'client/app/apps/execution-details/types';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

export const EXECUTION_DETAILS_EVENTS = {
  PLATE_SETUP_OPENED: 'execution-details-plate-setup-opened',
  STAGE_PREVIEW_OPENED: 'execution-details-stage-preview-opened',
  RESOURCES_LABWARE_EXPANDED: 'execution-details-resources-labware-expanded',
  RESOURCES_REAGENTS_EXPANDED: 'execution-details-resources-reagents-expanded',
} as const;

type EventData = {
  [EXECUTION_DETAILS_EVENTS.PLATE_SETUP_OPENED]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.STAGE_PREVIEW_OPENED]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.RESOURCES_LABWARE_EXPANDED]: ResourcesData;
  [EXECUTION_DETAILS_EVENTS.RESOURCES_REAGENTS_EXPANDED]: ResourcesData;
};

type MetricEvent =
  (typeof EXECUTION_DETAILS_EVENTS)[keyof typeof EXECUTION_DETAILS_EVENTS];

type ExecutionStageData = {
  main_device_model: string;
};

type ResourcesData = {
  device_models_comma_separated: string;
};

function trackExecutionDetailsStageEvent<TEvent extends MetricEvent>(
  event: TEvent,
  data: EventData[TEvent],
) {
  trackHeapEvent(event, data);
}

export function executionDetailsPlateSetupOpenedEvent(executionStage: ExecutionStage) {
  trackExecutionDetailsStageEvent(EXECUTION_DETAILS_EVENTS.PLATE_SETUP_OPENED, {
    main_device_model:
      executionStage.deviceExecutionMode.mainDevice.model.anthaLangDeviceClass,
  });
}

export function executionDetailsStagePreviewOpenedEvent(executionStage: ExecutionStage) {
  trackExecutionDetailsStageEvent(EXECUTION_DETAILS_EVENTS.STAGE_PREVIEW_OPENED, {
    main_device_model:
      executionStage.deviceExecutionMode.mainDevice.model.anthaLangDeviceClass,
  });
}

export function executionDetailsResourcesExpandedEvent(
  event:
    | typeof EXECUTION_DETAILS_EVENTS.RESOURCES_LABWARE_EXPANDED
    | typeof EXECUTION_DETAILS_EVENTS.RESOURCES_REAGENTS_EXPANDED,
  executionStages: ExecutionStage[],
) {
  trackExecutionDetailsStageEvent(event, {
    device_models_comma_separated: executionStages
      .map(stage => stage.deviceExecutionMode.mainDevice.model.anthaLangDeviceClass)
      .join(','),
  });
}
