import { isEnabled as isEnabledFeatureToggle } from 'common/features/featureTogglesForUI';
import { hasParallelTransferStage } from 'common/lib/mix';
import { MixPreviewStages, MixPreviewStep } from 'common/types/mixPreview';
import Colors from 'common/ui/Colors';
import { DeckItemState } from 'common/ui/components/simulation-details/mix/MixState';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';

const SLIDER_BAR_HEIGHT = 16;
const SLIDER_CURSOR_WIDTH = 12;
const SLIDER_CURSOR_HEIGHT = 24;

const STAGE_BREAKPOINT_WIDTH = SLIDER_CURSOR_WIDTH * 2;
const STAGE_BREAKPOINT_HEIGHT = SLIDER_CURSOR_HEIGHT;

const KEY_POINT_WIDTH = 4;

/**
 * We want the slider to re-render less but also to move smoothly with user cursor.
 * In order to do this we need to re-render the slider at least once per frame:
 * 1000 milliseconds / 60 fps = ~16ms
 */
const THROTTLE_TIMEOUT = 16;

export {
  KEY_POINT_WIDTH,
  SLIDER_BAR_HEIGHT,
  SLIDER_CURSOR_HEIGHT,
  SLIDER_CURSOR_WIDTH,
  STAGE_BREAKPOINT_HEIGHT,
  STAGE_BREAKPOINT_WIDTH,
  THROTTLE_TIMEOUT,
};

// Plain english descriptions of each kind of step to be displayed above slider.
// We apply a type to ensure there is an entry for every kind.
export function getStepKind(
  step: MixPreviewStep,
  deckItems: readonly DeckItemState[],
): string {
  switch (step.kind) {
    case 'tipbox_refresh':
      return 'Replace tipbox';
    case 'load':
      return 'Loading tips';
    case 'unload':
      return 'Unloading tips';
    case 'prompt':
      return 'Prompt';
    case 'error':
      return 'Error';
    // move_plate is due to be renamed because it can now be used for moving any deck
    // item, not just plates.
    case 'move_plate': {
      const itemKinds = step.effects.map(
        effect => deckItems.find(item => item.name === effect.plate_id)?.kind,
      );
      // There can be up to 2 effects, so we can join them with "and".
      let label = `Moving ${itemKinds.join(' and ')}`;
      if (step.gripper_name) {
        label += ` using ${step.gripper_name}`;
      }
      return label;
    }
    case 'parallel_transfer':
      return 'Transferring liquids';
    case 'parallel_dispense':
      return 'Dispensing liquids';
    case 'tip_wash':
      return 'Washing tips';
    case 'highlight':
      return step.title;
  }
}

export function getKeyPointColor(keyPoint: KeyPoint) {
  if (
    isEnabledFeatureToggle('MANUAL_INTERVENTION_HIGHLIGHT') &&
    keyPoint.isManualIntervention
  ) {
    return Colors.SKIN_TONE_DARK;
  }
  switch (keyPoint.kind) {
    case 'prompt':
      return Colors.KEY_POINT_DEFAULT;
    case 'error':
      return Colors.ERROR_LIGHT;
    case 'transfer':
      return Colors.KEY_POINT_TRANSFER;
    case 'stage':
      return Colors.GREY_60;
    default:
      // Unknown type of key point
      return Colors.KEY_POINT_DEFAULT;
  }
}

/**
 * Keypoint position varies depending on the stage, step and available width.
 * Here we adjust the position to cover all possible cases.
 */
export function getKeypointPosition(
  step: number,
  stageIndex: number,
  sliderWidth: number,
  stages: MixPreviewStages,
) {
  /**
   * The initial step of the 1st stage (stageIndex === 0) is "0" while
   * for all subsequent stages it is "1".
   */
  const indexOfInitialStep = stageIndex > 0 ? 1 : 0;
  const maxStep = stages[stageIndex].length;
  const stageWidth = sliderWidth / stages.length;

  const stepWidth = getAdjustedStepWidth(stageIndex, stages, sliderWidth);
  /**
   * Since intermediate stages start from step #1 we need to shift all
   * steps behind so the 1st step can meet borders with the StageBreakpoint.
   */
  const initialPositionOfThisStep =
    stageIndex * stageWidth + step * stepWidth - indexOfInitialStep * stepWidth;
  const finalPositionOfThisStep =
    initialPositionOfThisStep + SLIDER_CURSOR_WIDTH - KEY_POINT_WIDTH;

  const isSingleStage = stages.length === 1;
  const isLastStage = stageIndex === stages.length - 1;
  const isInitialStepOfTheStage = stageIndex > 0 ? step === 1 : step === 0;
  const isLastStepOfTheStage = step === maxStep;

  if (isSingleStage) {
    /**
     * Single stage case is separate because there are no stage breakpoints
     */
    return isInitialStepOfTheStage
      ? initialPositionOfThisStep
      : isLastStepOfTheStage
      ? finalPositionOfThisStep
      : /**
         * The below case is a KeyPoint somewhere in the middle of the slider cursor.
         * In this case we would like to hide the KeyPoint behind the slider cursor
         * but also right in the middle of that cursor.
         */
        finalPositionOfThisStep - KEY_POINT_WIDTH;
  } else if (isLastStage && isLastStepOfTheStage) {
    /**
     * Here its a multi-stage case by definition but also a last step of last stage.
     * In this case we simply return the position adjusted for final steps of stages.
     */
    return finalPositionOfThisStep;
  } else if (isLastStepOfTheStage) {
    /**
     * Here its an intermediate stage and final step of the stage.
     * In this case we want the edge of the KeyPoint to meet the edge
     * of the next stage breakpoint.
     */
    return initialPositionOfThisStep + 2 * KEY_POINT_WIDTH;
  } else if (isInitialStepOfTheStage) {
    /**
     * Here its an intermediate stage and initial step of the stage.
     * In this case we want the edge of the KeyPoint to meet the edge
     * of this stage breakpoint.
     */
    return initialPositionOfThisStep;
  } else {
    /**
     * Here its an intermediate stage and intermediate step.
     * We want to hide this KeyPoint behind the slider cursor
     * but also right in the middle of that cursor.
     */
    return initialPositionOfThisStep + SLIDER_CURSOR_WIDTH / 3;
  }
}

/**
 * The width of the step is depending on several factors:
 * - is it a single-stage or multi-stage simulation
 * - is this a step of an intermediate stage or the last stage (no breakpoint at the end)
 * - how many steps there are in this stage (each stage has equal width but different number of steps)
 *
 * e.g.
 *
 * The 1st stage of a Simulation starts with a zero-step (no steps/actions applied).
 * All subsequent stages should start with step #1 because the beginning of each
 * intermediate stage is a manual movement of plates onto the next stage area.
 *
 * Therefore, the width of the step in an intermediate stage should be bigger
 * in order to fill the stage piece of timeline with less steps (N-1 steps).
 */
export function getAdjustedStepWidth(
  currentStage: number,
  stages: MixPreviewStages,
  sliderWidth: number,
) {
  const stageWidth = sliderWidth / stages.length;
  const stepCount = stages[currentStage].length - (currentStage > 0 ? 1 : 0);

  const nextStage = Math.min(currentStage + 1, stages.length - 1);
  const isNextStageWithParallelTransfer = stages[nextStage].some(
    hasParallelTransferStage,
  );

  const isLastStage = currentStage === stages.length - 1;

  let offset: number;

  if (isLastStage) {
    /**
     * If its the last stage of the simulation then there is no breakpoint at the end.
     * Therefore, the width of the step has to allow the right border of the slider cursor
     * to meet the right border of the slider progress bar.
     */
    offset = SLIDER_CURSOR_WIDTH;
  } else if (isNextStageWithParallelTransfer) {
    /**
     * Here its an intermediate stage by definition so there are breakpoints
     * at the beginning and at the end.
     *
     * But also the next stage has parallel transfer.
     * This means that the breakpoint of the next stage is 2 times bigger
     * to contain a button opening parallel transfer slider.
     *
     * So here we adjust the step width to make sure that the cursor does not overlap
     * with the wider breakpoint of the next stage.
     */
    offset = STAGE_BREAKPOINT_WIDTH * 2 + SLIDER_CURSOR_WIDTH;
  } else {
    /**
     * Here its an intermediate stage and no parallel transfer.
     * Therefore, the step has be smaller to leave space for breakpoints and
     * make sure that edges of the cursor meet the edges of enclosing breakpoints.
     */

    offset = STAGE_BREAKPOINT_WIDTH + SLIDER_CURSOR_WIDTH;
  }

  return (stageWidth - offset) / stepCount;
}

/**
 * Returns previous KeyPoint in the same Simulation stage
 */
export function getPrevKeyPoint(keyPointIdx: number, stageKeyPoints: KeyPoint[]) {
  return keyPointIdx > 0 ? stageKeyPoints[keyPointIdx - 1] : null;
}

/**
 * Checks if 2 KeyPoints are adjacent on the Simulation timeline
 */
export function areAdjacent(keyPointA: KeyPoint | null, keyPointB: KeyPoint | null) {
  return Boolean(
    keyPointA &&
      keyPointB &&
      (keyPointA.step === keyPointB.step - 1 || keyPointA.step === keyPointB.step + 1),
  );
}

export function getManualActionSequences(keyPoints: readonly KeyPoint[][]) {
  const manualActionSequences: Map<number, number>[] = [];

  for (let stageIndex = 0; stageIndex < keyPoints.length; stageIndex++) {
    const stageKeyPoints = keyPoints[stageIndex];
    const stageManualActionSequences = new Map<number, number>();

    let start = -1;
    let count = -1;

    for (let keyPointIdx = 0; keyPointIdx <= stageKeyPoints.length; keyPointIdx++) {
      const keyPoint = stageKeyPoints[keyPointIdx];

      if (keyPoint?.isManualIntervention) {
        if (start === -1) {
          start = keyPointIdx;
          count = 1;
        } else if (areAdjacent(keyPoint, getPrevKeyPoint(keyPointIdx, stageKeyPoints))) {
          count += 1;
        } else {
          stageManualActionSequences.set(start, count);
          start = keyPointIdx;
          count = 1;
        }
      } else if (start !== -1) {
        stageManualActionSequences.set(start, count);
        start = -1;
        count = -1;
      }
    }
    manualActionSequences.push(stageManualActionSequences);
  }

  return manualActionSequences;
}
