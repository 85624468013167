import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';

/**
 * Returns the selected plate name if some plate name was selected before.
 * Otherwise, returns first available option in the dropdown.
 */
export default function useSelectedPlateName(
  plateStateMap: Map<string, PlateState>,
  plateOptions: { label: string; value: string }[],
) {
  const plateName = useWorkflowBuilderSelector(
    state => state.outputPreviewPanelProps.selectedPlateName,
  );
  return plateName && plateStateMap.has(plateName) ? plateName : plateOptions[0].value;
}
