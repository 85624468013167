import React from 'react';

import Typography from '@mui/material/Typography';

import { getResinName } from 'common/lib/chromatography';
import { formatMeasurementObj, formatWellPosition, roundNumber } from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const TOOLTIP_MAX_VISIBLE_ITEMS = 4;

export type WellTooltipTitleProps = {
  isPossiblyAllocated?: boolean;
  wellContents?: WellContents;
  wellLocationOnDeckItem: WellLocationOnDeckItem;
};

export function LegacyWellTooltipTitle({
  isPossiblyAllocated,
  wellContents,
  wellLocationOnDeckItem,
}: WellTooltipTitleProps) {
  const classes = useStyles();
  if (isPossiblyAllocated) {
    return (
      <Typography variant="subtitle2" color="textPrimary">
        Well at {formatWellPosition(wellLocationOnDeckItem)} may have liquids allocated
        from another element
      </Typography>
    );
  }
  if (!wellContents) {
    return (
      <Typography variant="subtitle2" color="textPrimary">
        Empty well at {formatWellPosition(wellLocationOnDeckItem)}
      </Typography>
    );
  }
  switch (wellContents.kind) {
    // This is undefined on older simulations, in which case it can be assumed this is a
    // liquid.
    case 'liquid_summary':
    case undefined:
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Liquid Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.type && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Liquid Type
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents?.type}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents?.total_volume.value > 0 && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Volume
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatMeasurementObj(wellContents?.total_volume)}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.solutes && (
              <>
                <dt className={classes.tooltipSubheader}>Final Concentrations</dt>
                {wellContents.solutes
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((solute, idx) => (
                    <dt key={solute.name + idx}>
                      <Typography variant="caption" color="textPrimary">
                        {`${formatMeasurementObj(solute.concentration)} ${solute.name}`}
                      </Typography>
                    </dt>
                  ))}
                {wellContents.solutes.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.solutes.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
            {wellContents.tags && (
              <>
                <dt className={classes.tooltipSubheader}>Metadata</dt>
                {wellContents.tags.slice(0, TOOLTIP_MAX_VISIBLE_ITEMS).map((tag, idx) => (
                  <React.Fragment key={idx}>
                    <dt>
                      <Typography variant="caption" color="textSecondary">
                        {tag.label || 'n/a'}
                      </Typography>
                    </dt>
                    <dd>
                      <Typography variant="caption" color="textPrimary">
                        {tag.value_float === undefined
                          ? tag.value_string || 'n/a'
                          : roundNumber(tag.value_float)}
                      </Typography>
                    </dd>
                  </React.Fragment>
                ))}
                {wellContents.tags.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.tags.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
          </dl>
        </>
      );
    case 'filter_matrix_summary':
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Chromatography Column at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    RoboColumn Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            <dt>
              <Typography variant="caption" color="textSecondary">
                Resin
              </Typography>
            </dt>
            <dd>
              <Typography variant="caption" color="textPrimary">
                {getResinName(wellContents)}
              </Typography>
            </dd>
            <dt>
              <Typography variant="caption" color="textSecondary">
                Column Volume
              </Typography>
            </dt>
            <dd>
              <Typography variant="caption" color="textPrimary">
                {formatMeasurementObj(wellContents?.total_volume)}
              </Typography>
            </dd>
          </dl>
        </>
      );
    case 'filtration_plate_matrix_summary':
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Resin Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.total_volume.value > 0 && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Resin Volume
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatMeasurementObj(wellContents.total_volume)}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.tags && wellContents.tags.length > 0 && (
              <>
                <dt className={classes.tooltipSubheader}>Metadata</dt>
                {wellContents.tags.slice(0, TOOLTIP_MAX_VISIBLE_ITEMS).map((tag, idx) => (
                  <React.Fragment key={idx}>
                    <dt>
                      <Typography variant="caption" color="textSecondary">
                        {tag.label || 'n/a'}
                      </Typography>
                    </dt>
                    <dd>
                      <Typography variant="caption" color="textPrimary">
                        {tag.value_float?.toFixed(2) ?? (tag.value_string || 'n/a')}
                      </Typography>
                    </dd>
                  </React.Fragment>
                ))}
                {wellContents.tags.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.tags.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
          </dl>
        </>
      );
    case 'mixture_summary':
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>

          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Mixture Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents?.total_volume.value > 0 && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Volume
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatMeasurementObj(wellContents?.total_volume)}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.sub_liquids && (
              <>
                <dt className={classes.tooltipSubheader}>Content Liquids</dt>
                {wellContents.sub_liquids
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((subLiquid, idx) => (
                    <dt key={subLiquid.name + idx}>
                      <Typography variant="caption" color="textPrimary">
                        {`${formatMeasurementObj(subLiquid.volume)} ${subLiquid.name}`}
                      </Typography>
                    </dt>
                  ))}
                {wellContents.sub_liquids.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.sub_liquids.length - TOOLTIP_MAX_VISIBLE_ITEMS}{' '}
                      more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
            {wellContents.solutes && (
              <>
                <dt className={classes.tooltipSubheader}>Final Concentrations</dt>
                {[...wellContents.solutes]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((solute, idx) => (
                    <dt key={solute.name + idx}>
                      <Typography variant="caption" color="textPrimary">
                        {`${formatMeasurementObj(solute.concentration)} ${solute.name}`}
                      </Typography>
                    </dt>
                  ))}
                {wellContents.solutes.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.solutes.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
          </dl>
        </>
      );
    case 'liquid_layer':
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    {wellContents.isGroup ? 'Group' : 'Liquid'} Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents?.total_volume && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Volume
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatMeasurementObj(wellContents.total_volume)}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents?.overallConcentration && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Concentration
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatMeasurementObj(wellContents.overallConcentration)}
                  </Typography>
                </dd>
              </>
            )}
          </dl>
        </>
      );
  }
}

const useStyles = makeStylesHook(({ spacing }) => ({
  tooltipTable: {
    display: 'grid',
    gap: spacing(2, 2),
    margin: spacing(2, 0, 0, 0),
    padding: spacing(2),
    minWidth: '200px',
    fontWeight: 'normal',
    '& dt': {
      gridColumn: 1,
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
    },
  },
  tooltipHeader: {
    textAlign: 'center',
    padding: spacing(1),
  },
  tooltipSubheader: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    whiteSpace: 'nowrap',
    paddingTop: spacing(2),
  },
}));
