import React from 'react';

import { Route, Switch } from 'react-router';

import { DOETemplatesScreen } from 'client/app/apps/experiments/DOETemplatesScreen';
import { DraftWorkflowsScreen } from 'client/app/apps/experiments/DraftWorkflowsScreen';
import ExampleGalleryScreen from 'client/app/apps/experiments/ExampleGalleryScreen';
import ExamplesScreen from 'client/app/apps/experiments/ExamplesScreen';
import { ExecutionsScreen } from 'client/app/apps/experiments/ExecutionsScreen';
import ExperimentsScreen from 'client/app/apps/experiments/ExperimentsScreen';
import { IncomingScreen } from 'client/app/apps/experiments/IncomingScreen';
import TutorialsScreen from 'client/app/apps/experiments/TutorialsScreen';
import WorkflowsScreen from 'client/app/apps/experiments/WorkflowsScreen';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import Screen404 from 'common/ui/components/Screen404';

export function ExperimentRouter() {
  return (
    <Switch>
      <Route
        exact
        path={experimentsRoutes.base.pathTemplate}
        component={ExperimentsScreen}
      />
      <Route
        exact
        path={experimentsRoutes.workflows.pathTemplate}
        component={WorkflowsScreen}
      />
      <Route
        exact
        path={experimentsRoutes.incoming.pathTemplate}
        component={IncomingScreen}
      />
      <Route
        exact
        path={experimentsRoutes.drafts.pathTemplate}
        component={DraftWorkflowsScreen}
      />
      <Route
        exact
        path={experimentsRoutes.executions.pathTemplate}
        component={ExecutionsScreen}
      />
      <Route
        exact
        path={experimentsRoutes.doeTemplates.pathTemplate}
        component={DOETemplatesScreen}
      />
      {/* TODO: Example Gallery content should be displayed in the Tutorials section */}
      <Route
        exact
        path={experimentsRoutes.exampleGallery.pathTemplate}
        component={ExampleGalleryScreen}
      />
      <Route
        exact
        path={experimentsRoutes.tutorials.pathTemplate}
        component={TutorialsScreen}
      />
      <Route
        exact
        path={experimentsRoutes.examples.pathTemplate}
        component={ExamplesScreen}
      />
      <Route component={Screen404} />
    </Switch>
  );
}
