import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { alpha } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import { ZOOM_LOG_DEBOUNCE_MS } from 'common/ui/AnalyticsConstants';
import Colors from 'common/ui/Colors';
import CanvasControl, {
  CanvasControlVariant,
} from 'common/ui/components/Workspace/CanvasControl';
import {
  DEFAULT_RATIO,
  DRAGGING_CURSOR,
  fitContentToAvailableSpace,
  getMinZoom,
  MAX_ZOOM,
  WHEEL_ZOOM_DIVISOR,
  ZOOM_CURSOR,
} from 'common/ui/components/Workspace/layoutUtils';
import { MouseModeControlContextProvider } from 'common/ui/components/Workspace/MouseModeControl';
import { VisualSelectionBox } from 'common/ui/components/Workspace/VisualSelectionBox';
import WorkspaceBackground, {
  WorkspaceVariant,
} from 'common/ui/components/Workspace/WorkspaceBackground';
import WorkspaceCoordinatesContext, {
  WorkspaceCoordinates,
} from 'common/ui/components/Workspace/WorkspaceCoordinatesContext';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useRefCallback from 'common/ui/hooks/useRefCallback';
import { useSelectionWithMouseControlContext } from 'common/ui/hooks/useSelection';
import { isLeftMouseClick } from 'common/ui/lib/ClickRecognizer';
import Keys from 'common/ui/lib/keyboard';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

type Props = {
  isShowAllButtonVisible: boolean;
  isShowHelpButtonVisible: boolean;
  isGridSwitchVisible?: boolean;
  setGridVisible?: (visible: boolean) => void;
  gridVisible?: boolean;
  onShowHelp?: () => void;
  onShowAll?: () => void;
  initialShowAll?: boolean;
  children: React.ReactNode;
  onPositionChange?: (
    pos: Position2d,
    zoom: number,
    width: number,
    height: number,
  ) => void;
  logCategory: string;
  canvasControlVariant: CanvasControlVariant;
  status?: JSX.Element;
  separateStatus?: boolean;
  variant?: WorkspaceVariant;
  visibleCanvasArea?: Dimensions;
  centerToPoint?: (
    cb: (x: number | undefined, y: number | undefined, zoomReset?: boolean) => void,
  ) => void;
  showHelpIcon?: ReactElement;
  showHelpTooltip?: string;
  disabled?: boolean;
  unzoomedContent?: ReactNode;
  controlsPortal?: HTMLDivElement | null;
};

type PrivProps = {
  forwardedRef: React.RefObject<HTMLDivElement>;
} & Props;

/**
 * The state for zooming and panning.
 */
type ContentBoxState = {
  zoom: number;
  x: number;
  y: number;
  isDragging: boolean;
  dragStartX: number;
  dragStartY: number;
  dragCurrentX: number;
  dragCurrentY: number;
};

function Workspace({
  visibleCanvasArea,
  centerToPoint,
  showHelpIcon,
  showHelpTooltip,
  isGridSwitchVisible,
  setGridVisible,
  gridVisible,
  controlsPortal,
  ...props
}: PrivProps) {
  const classes = useStyles();
  const context = useContext(RouteHiddenContext);

  // These are refs are crucially important aspects of the functionality.

  // `viewport` refers to the visual window through which the content can be
  // seen.
  const ref = useRef<HTMLDivElement>(null);

  const viewportRef = props.forwardedRef ?? ref;

  // `contentBox` refers to the stuff that will be zoomed in on or
  // panned around. It's the stuff inside the viewport.
  const contentBoxRef = useRef<HTMLDivElement | null>(null);

  // `anchor` is a dummy element that aids in getting the math right because
  // the `contentBox` moving around throws off the `viewport`'s bounding rect
  // calculations. `anchor` is basically a safe way to find the `viewport`'s
  // top, left corner in page-global coordinate space.
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const gridBackgroundRef = useRef<HTMLDivElement | null>(null);

  // hasRendered contains whether this component has been rendered on the visible
  // screen before. It's used to implement initial resizing of the contents, if
  // requested, since resizing cannot occur until the component has rendered and
  // thus had its size calculated.
  const [hasRendered, setHasRendered] = useState(false);

  // Here we pass the viewportRef in order to prevent
  // the undelivered notifications error inside ResizeObserver.
  // For more reference please visit:
  // https://github.com/juggle/resize-observer#notification-schedule
  const viewportSize = useResizeObserver({ ref: viewportRef });

  const viewportDims = useMemo(
    () => ({
      top: 0,
      left: 0,
      width: viewportSize.width ?? 0,
      height: viewportSize.height ?? 0,
    }),
    [viewportSize.height, viewportSize.width],
  );

  // `mergeRefs` let you combine two refs into one. It's useful when you have two refs, usually a
  // local/forwarded one and one provided by a library, and you need to apply both to the same element.
  // In this case, `useResizeObserver` gives us a ref that we need to apply to the observed element, but
  // it's opaque, meaning we can't call `ref.current` on it and read the size of the viewport directly,
  // which we need to do in a couple of places, so we merge our own ref with theirs.
  //
  // Note, that it is possible to supply `useResizeObserver()` with an external ref, but the library
  // has some logic to watch its own ref's assignment and this breaks with an external ref. The result
  // is that it doesn't read the target element's size on mount, only on a later resize event, and that
  // breaks things here, so we need to merge refs, which is their recommended approach:
  // https://github.com/ZeeCoder/use-resize-observer#getting-the-raw-element-from-the-default-refcallback
  const mergedRef = mergeRefs([viewportSize.ref, viewportRef]);

  const initialContentBoxState: ContentBoxState = {
    zoom: 1,
    x: 0,
    y: 0,
    isDragging: false,
    dragStartX: 0,
    dragStartY: 0,
    dragCurrentX: 0,
    dragCurrentY: 0,
  };

  const [contentBoxState, setContentBoxState] =
    useState<ContentBoxState>(initialContentBoxState);

  const startDrag = (e: React.PointerEvent) => {
    /**
     * We want to start dragging only on the left mouse click.
     * We don't want the odd behaviour when user accidentally right clicks
     * and dragging gets sticked to the cursor until user makes an additional click.
     */
    if (!isLeftMouseClick(e)) return;

    setContentBoxState({
      ...contentBoxState,
      isDragging: true,
      dragStartX: e.clientX,
      dragStartY: e.clientY,
      dragCurrentX: e.clientX,
      dragCurrentY: e.clientY,
    });
  };

  const drag = (e: React.PointerEvent) => {
    if (!contentBoxState.isDragging) {
      return;
    }

    // Prevent text selection while we're dragging
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }

    if (contentBoxState.isDragging) {
      setContentBoxState({
        ...contentBoxState,
        dragCurrentX: e.clientX,
        dragCurrentY: e.clientY,
      });
    }
  };

  const cancelDrag = () => {
    setContentBoxState({
      ...contentBoxState,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      dragCurrentX: 0,
      dragCurrentY: 0,
    });
  };

  // Manually set the zoom based around a specific point in absolute
  // coordinates.
  const zoomAtPoint = useCallback(
    (zoom: number, x: number, y: number) => {
      const cb = contentBoxRef.current;
      const vp = viewportRef.current;
      const a = anchorRef.current;

      if (!(cb && vp && a)) {
        return;
      }

      const currentZoom = contentBoxState.zoom;
      const cbRect = cb.getBoundingClientRect();
      // In some instances, the values for cb.offsetWidth and cb.offsetHeight are 0 (e.g. if the
      // reference div has no children) but we cannot allow contentBoxWidth or contentBoxHeight to
      // be set to 0 as this results in a division by 0 below, and Infinity values returned. So,
      // if cb.offsetWidth or cb.offsetHeight are 0, we force them to be 1.
      // currentZoom is always expected to be between `MIN_ZOOM` or `MAX_ZOOM`.
      const contentBoxWidth = Math.max(cb.offsetWidth ?? 0, 1) * currentZoom;
      const contentBoxHeight = Math.max(cb.offsetHeight ?? 0, 1) * currentZoom;
      const newWidth = Math.round(cb.offsetWidth * zoom);
      const newHeight = Math.round(cb.offsetHeight * zoom);
      const aRect = a.getBoundingClientRect();
      const pointXRatio = (x - cbRect.x) / contentBoxWidth;
      const newX = Math.round(-newWidth * pointXRatio) + (x - aRect.left);

      const pointYRatio = (y - cbRect.y) / contentBoxHeight;

      const newY = Math.round(-newHeight * pointYRatio) + (y - aRect.top);

      setContentBoxState({
        ...contentBoxState,
        zoom,
        x: newX,
        y: newY,
      });
      props.onPositionChange?.(
        { x: newX, y: newY },
        zoom,
        Math.round(vp.offsetWidth),
        Math.round(vp.offsetHeight),
      );
    },
    [viewportRef, contentBoxState, setContentBoxState, props],
  );

  const minZoom = useRef<number>(1);

  const debouncedZoomEventLog = useMemo(() => {
    return debounce((zoom: number, label: string) => {
      logEvent(label, props.logCategory, zoom.toString());
    }, ZOOM_LOG_DEBOUNCE_MS);
  }, [props.logCategory]);

  /**
   * Derrived from equation:
   *
   * ratio: from 0 to 1
   * if: zoom = (MAX_ZOOM - minZoom) * ratio + minZoom
   * then: ratio = (zoom - minZoom) / (MAX_ZOOM - minZoom)
   */
  const currentZoomRatio =
    (contentBoxState.zoom - minZoom.current) / (MAX_ZOOM - minZoom.current);

  const zoom = useCallback(
    (e: React.WheelEvent) => {
      const rawChange = e.deltaY / WHEEL_ZOOM_DIVISOR;
      const currentZoom = contentBoxState.zoom;

      if (currentZoom >= MAX_ZOOM && rawChange < 0) {
        return;
      }

      let z = currentZoom - rawChange;
      if (z >= MAX_ZOOM) {
        if (z > MAX_ZOOM) {
          logEvent('attempt-to-overzoom', props.logCategory);
        }
        z = MAX_ZOOM;
      } else {
        if (z < minZoom.current) {
          logEvent('attempt-to-underzoom', props.logCategory);
          z = minZoom.current;
        }
        if (z === currentZoom) {
          return;
        }
      }

      debouncedZoomEventLog(z, 'zoom-with-mouse-wheel');
      zoomAtPoint(z, e.clientX, e.clientY);
    },
    [debouncedZoomEventLog, props.logCategory, contentBoxState.zoom, zoomAtPoint],
  );

  const setZoomFromSlider = (ratio: number) => {
    const z = (MAX_ZOOM - minZoom.current) * ratio + minZoom.current;
    debouncedZoomEventLog(z, 'zoom-with-slider');
    setZoomFromCenterScreen(z);
  };

  const setZoomFromCenterScreen = useCallback(
    (z: number) => {
      const cb = contentBoxRef.current;
      const vp = viewportRef.current;
      const a = anchorRef.current;

      if (!(cb && vp && a)) {
        return;
      }

      const aRect = a.getBoundingClientRect();
      // Figure out the center of the viewpoint in absolute coordinates
      const vpVerticalCenter = aRect.top + Math.round(vp.clientHeight / 2);
      const vpHorizontalCenter = aRect.left + Math.round(vp.clientWidth / 2);
      zoomAtPoint(z, vpHorizontalCenter, vpVerticalCenter);
    },
    [viewportRef, zoomAtPoint],
  );

  const showAll = useCallback(() => {
    props.onShowAll?.();

    if (!viewportRef.current) {
      return;
    }

    const { position, zoom } = fitContentToAvailableSpace(
      visibleCanvasArea,
      contentBoxRef.current,
      viewportRef.current,
      minZoom.current,
    );

    setContentBoxState({
      ...contentBoxState,
      zoom,
      ...position,
    });

    props.onPositionChange?.(
      position,
      zoom,
      Math.round(viewportRef.current.offsetWidth),
      Math.round(viewportRef.current.offsetHeight),
    );

    logEvent('show-all', props.logCategory, 'zoom', zoom.toString());
  }, [props, visibleCanvasArea, viewportRef, setContentBoxState, contentBoxState]);

  const applyZoomAndCenterToArea = useCallback(
    (dim: Dimensions) => {
      const contentBoxPosition = getContentBoxPosition(
        viewportRef,
        contentBoxRef,
        contentBoxState,
        true,
      );
      const zoomLevel = contentBoxState.zoom;
      let ratio = DEFAULT_RATIO;
      const visibleArea = visibleCanvasArea ?? viewportDims;

      const simpleZoomToClick = isSelectionJustAclick(dim);
      if (!simpleZoomToClick) {
        // We will zoom as much as we can without cropping the selection.
        // In other words we want the viewport to contain our selection.
        const ratioW = visibleArea.width / dim.width;
        const ratioH = visibleArea.height / dim.height;
        ratio = Math.min(ratioW, ratioH);
      }
      let newZoom = zoomLevel * ratio;

      // if newZoom is to big, we won't zoom, but we will still center.
      if (newZoom > MAX_ZOOM) {
        newZoom = MAX_ZOOM;
        ratio = newZoom / zoomLevel;
      }

      /*
      To center into the selection we need to apply the following translations:
      a. move top left of content box to the middle of the viewport.
         This is not affected by the zoom, the top left of the contentBox at the same position.
      b. move the center of our selection to the top left of contentBox. This need to happens after the zoom.
      */
      const selectionMiddleX = dim.left + dim.width / 2;
      const selectionMiddleY = dim.top + dim.height / 2;
      const viewportMiddleX = visibleArea.left + visibleArea.width / 2;
      const viewportMiddleY = visibleArea.top + visibleArea.height / 2;

      // a. cbVpMid is the vector from content box top left  to middle of viewport.
      const cbVpMidX = viewportMiddleX - contentBoxPosition.x;
      const cbVpMidY = viewportMiddleY - contentBoxPosition.y;

      // b. selMidCb is the zoom scaled vector from selection's middle to content box top left.
      const selMidCbX = (contentBoxPosition.x - selectionMiddleX) * ratio;
      const selMidCbY = (contentBoxPosition.y - selectionMiddleY) * ratio;

      const newX = contentBoxPosition.x + cbVpMidX + selMidCbX;
      const newY = contentBoxPosition.y + cbVpMidY + selMidCbY;

      setContentBoxState(s => ({
        ...s,
        zoom: newZoom,
        x: newX,
        y: newY,
      }));

      props.onPositionChange?.(
        { x: newX, y: newY },
        newZoom,
        Math.round(viewportRef.current?.offsetWidth ?? 1),
        Math.round(viewportRef.current?.offsetHeight ?? 1),
      );

      return simpleZoomToClick;
    },

    [
      props,
      setContentBoxState,
      contentBoxState,
      viewportDims,
      viewportRef,
      visibleCanvasArea,
    ],
  );

  const {
    selectionDimensions,
    inSelectionMode,
    setInSelectionMode,
    isSelecting,
    shimNode,
  } = useSelectionWithMouseControlContext(
    viewportDims,
    applyZoomAndCenterToArea,
    'zoom',
    {
      displayShimInSelectionMode: true,
      cursor: ZOOM_CURSOR,
    },
  );

  const contentBoxPosition = useMemo(() => {
    return getContentBoxPosition(
      viewportRef,
      contentBoxRef,
      contentBoxState,
      inSelectionMode,
    );
  }, [inSelectionMode, contentBoxState, viewportRef]);

  const stopDrag = useCallback(() => {
    const { x, y } = contentBoxPosition;
    const userHasDragged =
      contentBoxState.dragCurrentX - contentBoxState.dragStartX !== 0 ||
      contentBoxState.dragCurrentY - contentBoxState.dragStartY !== 0;
    if (userHasDragged) {
      logEvent('drag', props.logCategory, `${x.toFixed(0)}, ${y.toFixed(0)}`);
    }
    setContentBoxState({
      ...contentBoxState,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      dragCurrentX: 0,
      dragCurrentY: 0,
      x,
      y,
    });

    props.onPositionChange?.(
      { x, y },
      contentBoxState.zoom,
      Math.round(viewportDims.width),
      Math.round(viewportDims.height),
    );
  }, [
    contentBoxPosition,
    props,
    setContentBoxState,
    contentBoxState,
    viewportDims.height,
    viewportDims.width,
  ]);

  const onKeyDown = useRefCallback(
    useCallback(
      (e: KeyboardEvent) => {
        // do nothing if view is hidden
        if (context.hidden) {
          return;
        }
        // Don't respond unless the event is on the body. If the target is some
        // input or textarea, we don't want to do anything.
        if (e.target !== document.body) {
          return;
        }

        const { key } = e;
        switch (key) {
          case Keys.A:
            // Ctrl + A is handled inside the WorkflowLayout
            if (e.ctrlKey || e.metaKey) {
              break;
            }
            // This works in conjunction with the WorkflowLayout to snap the
            // layout to the origin and adjust the zoom.
            logEvent('hotkey', props.logCategory, 'show-all');
            showAll();
            break;
          case Keys.ZERO:
            logEvent('hotkey', props.logCategory, 'reset-to-default-zoom');
            setZoomFromCenterScreen(1);
            break;
          case Keys.Z:
            logEvent('hotkey', props.logCategory, 'zoom mode');
            if (!e.repeat && !e.ctrlKey && !e.metaKey) {
              setInSelectionMode(val => !val);
            }
            break;
          case Keys.ESCAPE:
            logEvent('hotkey', props.logCategory, 'escape mode');
            setInSelectionMode(false);
            break;
          default:
            break;
        }
      },
      [
        context.hidden,
        props.logCategory,
        setInSelectionMode,
        setZoomFromCenterScreen,
        showAll,
      ],
    ),
  );

  /**
   * In Chrome, pinch zooming causes the entire page to be zoomed in. Normally
   * this is fine, but in the workspace, the user ends up seeing both the
   * workspace and page zoom in. To prevent the gesture from propagating to the
   * document body, we can listen for wheel events with ctrlKey (this is
   * simulated by Chrome) and prevent the event's default behaviour.
   */
  const stopPinchZoomPropagation = useCallback((e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  }, []);

  useLayoutEffect(() => {
    // When should showAll happen? Intuitively it should happen when the component is
    // mounted. This works for the workflow builder. However, for the mix view, the component
    // is mounted before it becomes visible (ie. when the user is on the Overview tab), so
    // the DOM dimensions retrieved in showAll will be 0. Instead, every time the component
    // updates we check if the DOM dimensions are greater than 0, and the first time this
    // happens we call showAll. Weirdly, on the workflow builder some DOM rendering is still
    // happening on this first update, so we wait until the first animation frame :(
    if (!hasRendered && viewportRef.current && viewportRef.current.clientWidth > 0) {
      setHasRendered(true);
      if (props.initialShowAll) {
        window.requestAnimationFrame(() => showAll());
      }
    }
  }, [hasRendered, props.initialShowAll, showAll, viewportRef]);

  useEffect(() => {
    // Chrome defaults to a passive handler for wheel events, meaning
    // preventDefault won't work. React does not support setting passive so we
    // have to manually add/remove the listener
    // (https://github.com/facebook/react/issues/6436)
    document.addEventListener('wheel', stopPinchZoomPropagation, {
      passive: false,
    });
    return () => {
      document.removeEventListener('wheel', stopPinchZoomPropagation);
    };
  }, [stopPinchZoomPropagation]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    /**
     * We would like to center the workspace to a specific (x, y) point when centerArea is set.
     * The passed-in callback is executed only if centerArea is defined.
     * When the centerArea changes the reference to centerToPoint() also change and this effect is executed.
     * As a result the workspace is centered to the new centerArea or nothing is happening
     * in case the centerArea was set to undefined.
     */
    centerToPoint?.((x: number | undefined, y: number | undefined, zoomReset = true) => {
      setContentBoxState(s => {
        const newX = x ?? s.x;
        const newY = y ?? s.y;
        return {
          ...s,
          zoom: zoomReset ? 1 : s.zoom,
          x: zoomReset ? newX : newX * s.zoom,
          y: zoomReset ? newY : newY * s.zoom,
        };
      });
    });
  }, [centerToPoint, setContentBoxState]);

  const cursor = (() => {
    if (inSelectionMode) {
      return ZOOM_CURSOR;
    }
    if (contentBoxState.isDragging) {
      return DRAGGING_CURSOR;
    }
    return 'inherit';
  })();

  const workspaceCoords = useMemo<WorkspaceCoordinates>(() => {
    const zoom = contentBoxState.zoom;
    const left = (contentBoxPosition.x * -1) / zoom;
    const top = (contentBoxPosition.y * -1) / zoom;
    const width = viewportDims.width / zoom;
    const height = viewportDims.height / zoom;
    const right = left + width;
    const bottom = top + height;

    const result: WorkspaceCoordinates = {
      visibleWorkspaceArea: {
        left,
        top,
        width,
        height,
        bottom,
        right,
        zoom,
      },
      toScreenX(x: any): any {
        return x === undefined ? undefined : (x - left) * zoom;
      },
    };

    return result;
  }, [
    contentBoxPosition.x,
    contentBoxPosition.y,
    contentBoxState.zoom,
    viewportDims.height,
    viewportDims.width,
  ]);

  return (
    <div className={classes.workspaceContainer}>
      <div
        ref={mergedRef}
        className={classes.viewPort}
        onPointerDown={startDrag}
        onPointerMove={drag}
        onPointerUp={stopDrag}
        onPointerCancel={cancelDrag}
        onPointerLeave={stopDrag}
        onWheel={zoom}
        style={{
          cursor,
        }}
      >
        {shimNode}
        {isSelecting && (
          <VisualSelectionBox
            borderColor={Colors.INFO_MAIN}
            backgroundColor={alpha(Colors.INFO_MAIN, 0.1)}
            {...selectionDimensions}
          />
        )}
        <div
          ref={gridBackgroundRef}
          className={classes.gridBackground}
          style={{
            transform: `scale(${Math.abs(contentBoxState.zoom)})`,
            left: `${contentBoxPosition.x}px`,
            top: `${contentBoxPosition.y}px`,
          }}
        >
          <WorkspaceBackground
            zoomRatio={currentZoomRatio}
            variant={props.variant}
            disabled={props.disabled}
          />
        </div>
        <div ref={anchorRef} style={{ position: 'absolute', top: 0, left: 0 }} />
        <div
          ref={contentBox => {
            contentBoxRef.current = contentBox;
            minZoom.current = getMinZoom(contentBox, visibleCanvasArea, viewportDims);
          }}
          className={classes.contentBox}
          style={{
            position: 'absolute',
            transform: `scale(${Math.abs(contentBoxState.zoom)})`,
            left: `${contentBoxPosition.x}px`,
            top: `${contentBoxPosition.y}px`,
          }}
        >
          <ZoomContext.Provider value={contentBoxState.zoom}>
            {props.children}
          </ZoomContext.Provider>
        </div>
        <WorkspaceCoordinatesContext.Provider value={workspaceCoords}>
          {props.unzoomedContent}
        </WorkspaceCoordinatesContext.Provider>
      </div>
      <CanvasControl
        canvasControlVariant={props.canvasControlVariant}
        disabledMouseSelect
        gridVisible={gridVisible}
        setGridVisible={setGridVisible}
        isGridSwitchVisible={isGridSwitchVisible}
        onShowAll={showAll}
        onShowHelp={props.onShowHelp}
        onZoomChange={setZoomFromSlider}
        currentZoomRatio={currentZoomRatio}
        disableShowAllButton={!props.isShowAllButtonVisible}
        status={props.status}
        separateStatus={props.separateStatus}
        showHelpIcon={showHelpIcon}
        showHelpTooltip={showHelpTooltip}
        showMouseModeControl
        portal={controlsPortal}
      />
    </div>
  );
}

// Permit a ref to be set on <Workspace> element, which can be then be used to, for
// example, constrain movement of draggable elements
export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <MouseModeControlContextProvider>
    <Workspace forwardedRef={ref as React.RefObject<HTMLDivElement>} {...props} />
  </MouseModeControlContextProvider>
));

const useStyles = makeStylesHook({
  viewPort: {
    cursor: 'move',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  /* This node will move and scale exactly with the contentBox. Because of this,
   * it also needs to be at (0, 0) and transform from the top left just like
   * the contentBox.
   */
  gridBackground: {
    position: 'absolute',
    transformOrigin: 'top left',
    zIndex: -1000,
  },
  contentBox: {
    position: 'absolute',
    transformOrigin: 'top left',
    zIndex: 1,
    // We do not want text to be highlighted when doing selection and zoom
    '& ::selection': {
      backgroundColor: 'inherit',
    },
  },

  zoomer: {
    paddingRight: '16px',
  },

  workspaceControls: {
    bottom: '10px',
    display: 'flex',
    right: '16px',
    position: 'absolute',
    zIndex: 2,
  },

  /* When the Workspace is used in the MixPreview, the container disappears
   * so we set a height and width here to keep it visible.
   */
  workspaceContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
});

function getContentBoxPosition(
  viewportRef: React.RefObject<HTMLDivElement>,
  contentBoxRef: React.MutableRefObject<HTMLDivElement | null>,
  contentBoxState: ContentBoxState,
  inSelectionMode: boolean,
) {
  const viewport = viewportRef.current;
  const contentBox = contentBoxRef.current;

  if (!viewport || !contentBox) {
    return { x: 0, y: 0 };
  }

  if (inSelectionMode) {
    const { x, y } = contentBoxState;
    return { x, y };
  }

  const { dragCurrentX, dragCurrentY, dragStartX, dragStartY } = contentBoxState;
  const dragDeltaX = dragCurrentX - dragStartX;
  const dragDeltaY = dragCurrentY - dragStartY;
  const x = contentBoxState.x + dragDeltaX;
  const y = contentBoxState.y + dragDeltaY;
  return { x, y };
}

/**
 * If the selected area is small enough we consider that the user did not inted to select an area,
 * but only slightly moved the mouse while clicking.
 */
function isSelectionJustAclick(dim: Dimensions) {
  return dim.height <= 2 && dim.width <= 2;
}
