import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import times from 'lodash/times';

import {
  Major,
  SchemaVersionValidator,
  VerifySchemaVersionMatrix,
} from 'common/lib/schema-version';
import { Action, Deck, ParallelTransferAction, Rule } from 'common/types/mix';
import {
  LiquidMovement,
  MixPreview,
  MixPreviewFailed,
  MixPreviewStages,
  MixPreviewStep,
  ParallelDispenseStep,
  ParallelTransferStep,
} from 'common/types/mixPreview';
import { StepsJson } from 'common/types/steps';

/** Format of the actions.json file before July 2020. */
type ActionsJsonV1 = { actions: Action[]; version: string };
/** Format of the actions.json file after July 2020. */
type ActionsJsonV2MultiTask = {
  tasks: {
    actions: Action[];
  }[];
  /**
   * Rules define what should happen under certain conditions (see the comment for Rule).
   */
  rules?: { [id: string]: Rule };
  version: string;
};
/** Format of the actions.json file after Feb 2024 */
export type ActionsJsonV3MultiStage = {
  tasks: {
    task_id: string;
    stage_id?: string;
    actions: Action[];
  }[];
  /**
   * Rules define what should happen under certain conditions (see the comment for Rule).
   */
  rules?: { [id: string]: Rule };
  /**
   * Simulation stages which are defined in workflow
   */
  stages: { id: string; name: string }[];
  version: string;
};

/**
 * Older Simulations have actions.json in the older format,
 * newer Simulations have actions.json in the newer format.
 * The UI must be able to render both formats.
 */
type ActionsJson = ActionsJsonV1 | ActionsJsonV2MultiTask | ActionsJsonV3MultiStage;

/**
 * Format of the layout.json file.
 * It stays the same both before July 2020 and for multi-device workflows after July 2020.
 */
type LayoutJson = Deck;

/**
 * Prior to Mar 2021, actions.json did not have rules.
 */
function getRulesFromJson(actionsJson: ActionsJson): { [id: string]: Rule } {
  if ('rules' in actionsJson) {
    return actionsJson.rules ?? {};
  }
  return {};
}

/**
 * Returns Simulation Preview steps grouped by simulation stages.
 *
 * Steps are built from `actions.json` file contents.
 *
 * Currently there are 3 versions of `actions.json` file format which is :
 * - before July 2020: flat list of actions
 * - before Feb 2024: actions are distributed by tasks
 * - after Feb 2024: actions are distributed by tasks within simulation stages
 */
function getPreviewStages(actionsJson: ActionsJson): MixPreviewStages {
  if ('stages' in actionsJson) {
    return getPreviewStagesV3(actionsJson);
  } else if ('tasks' in actionsJson) {
    return getPreviewStagesV2(actionsJson);
  } else {
    return getPreviewStagesV1(actionsJson);
  }
}

/**
 * Parses the v1 `actions.json` format into a single simulation stage with all the steps.
 */
function getPreviewStagesV1(actionsJson: ActionsJsonV1): MixPreviewStages {
  const actions = [...actionsJson.actions];
  const lastAction = actions[actions.length - 1];
  actions.push(buildFinalPreviewStep(lastAction));
  return [flattenGroupedTransfers(actions)];
}

/**
 * Parses the v2 `actions.json` format into a single simulation stage with all the steps.
 */
function getPreviewStagesV2(actionsJson: ActionsJsonV2MultiTask): MixPreviewStages {
  const actions = actionsJson.tasks.flatMap(task => task.actions);
  const lastAction = actions[actions.length - 1];
  actions.push(buildFinalPreviewStep(lastAction));
  return [flattenGroupedTransfers(actions)];
}

/**
 * Parses the v3 `actions.json` format into multiple simulation stages
 * with all the steps distributed between stages using `stage_id`.
 */
function getPreviewStagesV3(actionsJson: ActionsJsonV3MultiStage): MixPreviewStages {
  const tasks = produce(actionsJson.tasks, draft => {
    // Add a step at the end to show the final state of the deck
    const lastTask = draft[draft.length - 1];
    const lastAction = lastTask.actions[lastTask.actions.length - 1];
    lastTask.actions.push(buildFinalPreviewStep(lastAction) as WritableDraft<Action>);
  });
  return map(groupBy(tasks, 'stage_id'), group =>
    flattenGroupedTransfers(group.flatMap(({ actions }) => actions)),
  );
}

function buildFinalPreviewStep(action: Action): Action {
  return {
    kind: 'prompt',
    message: 'Finished',
    /**
     * Finishing is not a manual action required from user.
     * But it is also not an automated one. Hence, it does not have a time estimate.
     *
     * Here we assign an artificial time estimate to exclude final step from the list of manual actions
     * at the same time making sure it has no impact on the simulation time estimate.
     */
    time_estimate: Number.MIN_VALUE,
    cumulative_time_estimate: action?.cumulative_time_estimate || 0,
  };
}

export function createMixPreview(
  layoutJson: LayoutJson,
  actionsJson: ActionsJson,
  stepsJson?: StepsJson,
  jobId?: string,
): MixPreview | MixPreviewFailed {
  return tryCreateMixPreviewFromResponse(
    jobId || 'NO_JOB_ID',
    layoutJson,
    actionsJson,
    stepsJson,
  );
}

/**
 * The total time for a preview is the final step's cumulative time estimate.
 */
export function getPreviewTotalDuration(stages: MixPreviewStages): number {
  return stages.reduce(
    (total, nextStage) =>
      (total += nextStage[nextStage.length - 1]?.cumulative_time_estimate || 0),
    0,
  );
}

function tryCreateMixPreviewFromResponse(
  jobId: string,
  layoutJson: LayoutJson,
  actionsJson: ActionsJson,
  stepsJson?: StepsJson,
): MixPreview | MixPreviewFailed {
  const valid = validateMixPreviewResponse(layoutJson, actionsJson);
  if (!valid) {
    // Represents a failed response, along with the job it was requested for.
    // This happens when the job is very old (2018 and older) and doesn't have
    // any data for the preview.
    return { jobId, valid: false };
  }

  return {
    jobId,
    // The layout.json file exactly matches our representation of the Deck
    deck: layoutJson,
    rules: getRulesFromJson(actionsJson),
    stages: getPreviewStages(actionsJson),
    // Flag so we can match on this in the UI
    valid: true,
    instructions: stepsJson,
  };
}

const ACTION_KEY = 'actions.json';
const LAYOUT_KEY = 'layout.json';

/**
 * List of actions.json and layout.json versions supported by the preview. Note the current system is that new major
 * versions require an entry here, but new minor versions must be backwards compatible with this code.
 */
const SUPPORTED_SCHEMA_VERSIONS = [
  new Map<string, SchemaVersionValidator>([
    [ACTION_KEY, Major(1).Minor(0, 1)],
    [LAYOUT_KEY, Major(1).Minor(0, 1)],
  ]),
  new Map<string, SchemaVersionValidator>([
    [ACTION_KEY, Major(2).MinorMinimum(0)],
    [LAYOUT_KEY, Major(1).MinorMinimum(1)],
  ]),
];

function validateMixPreviewResponse(layout: LayoutJson, actions: ActionsJson): boolean {
  if (!actions) {
    console.warn('actions.json not found');
    return false;
  }
  if (!layout) {
    console.warn('layout.json not found');
    return false;
  }
  // For computational workflows, they may return an actions/layout pair, but they have no actual
  // data in them. Check if the deck is empty, which indicates a simulation that contains no liquid
  // handling.
  if (Object.keys(layout.before.positions).length === 0) {
    console.warn('layout.json contains no positions');
    return false;
  }

  try {
    VerifySchemaVersionMatrix(
      new Map<string, string>([
        [ACTION_KEY, actions.version],
        [LAYOUT_KEY, layout.version],
      ]),
      ...SUPPORTED_SCHEMA_VERSIONS,
    );
  } catch (e) {
    console.warn(
      `The combination of actions.json version ${actions.version} ` +
        `and layout.json version ${layout.version} is not supported. See ${e.message}`,
    );
    return false;
  }
  return true;
}

/**
 * Transfers within actions.json can contain multiple sub-actions, including
 * parallel transfers (which themselves can contain multiple dispenses), tip
 * washes, tip loading/unloading, and refreshing tipboxes. In the UI we wish to
 * break this up into multiple steps.
 */
function flattenGroupedTransfers(steps: Action[]): MixPreviewStep[] {
  const expandedSteps = [];
  for (const step of steps) {
    if (step.kind === 'transfer') {
      // A 'transfer' step contains a group of atomic steps, such as tip
      // washing, parallel transfers, and refreshing tipboxes.
      for (const child of step.children) {
        if (child.kind === 'parallel_transfer') {
          // A parallel transfer can contain multiple dispenses for a single
          // aspirate. In the UI we wish to display these dispenses separately,
          // so we expend each dispense into a separate step.
          expandedSteps.push(...flattenMultiDispense(child));
        } else {
          expandedSteps.push(child);
        }
      }
    } else {
      // Leave other steps as they are
      expandedSteps.push(step);
    }
  }
  // The tip unload actions (dropping tips in tip waste) are not interesting
  // for the user. We need to remove them here (not in MixState), so that
  // the total number of steps goes down.
  return removeUninterestingSteps(expandedSteps);
}

type ParallelTransferOrDispenseStep = ParallelTransferStep | ParallelDispenseStep;

/**
 * Some transfers may have multiple dispenses. For example, the tip aspirates
 * 600ul, then dispenses 200ul to A1, 200ul to A2, and 200ul to A3. To show each
 * of these in a single preview step would lead to information overload (ie too
 * many arrows). This function breaks up multi-dispenses into separate
 * transfers, which show in the preview as separate arrows from the location of
 * the original aspirate, to the location of the each dispense.
 */
export function flattenMultiDispense(
  transfer: ParallelTransferAction,
): ParallelTransferOrDispenseStep[] {
  // Get the number of dispenses within this transfer. The schema allows each
  // channel to specify a separate number of `to` destinations, so we need to
  // check each to find out the number of dispenses.
  const multiDispenseCount = Math.max(
    ...Object.values(transfer.channels).map(({ to }) => to.length),
  );

  // The current actions.json schema doesn't break down the time spent at each
  // dispense. This is due to be improved when we review the schema. For now, we
  // can divide the total time_estimate by the number of dispenses to get a
  // rough estimate suitable for the preview.
  const timeForEachDispense = transfer.time_estimate / multiDispenseCount;
  const timeAtStartOfTransfer =
    transfer.cumulative_time_estimate - transfer.time_estimate;

  // Create a new parallel transfer step for each dispense
  return times<ParallelTransferOrDispenseStep>(multiDispenseCount, multiDispenseIndex => {
    const channels: Record<string, LiquidMovement> = {};
    // Add the dispense for each channel
    for (const [channel, action] of Object.entries(transfer.channels)) {
      const destination = action.to[multiDispenseIndex];
      if (!destination) {
        // If destination is null, then this channel is not doing anything during this
        // dispense.
        continue;
      }
      channels[channel] = {
        // Copy across all the liquid properties from the original parallel
        // transfer (including `from`)
        ...action,
        // If there's a filter, use that. Otherwise it's the same as liquidDestination
        tipDestination: destination.filter ?? destination,
        // Final destination of the liquid
        liquidDestination: destination,
        // actions.json v1.1 has a volume attached to each `to` destination. For
        // v1.0, fallback to volume defined in the transfer.
        volume: destination.volume_change ?? action.volume,
        multiDispenseIndex,
        multiDispenseCount,
      };
    }
    return {
      // The first dispense is a complete transfer - i.e has a 'from' and 'to'.
      // For remaining dispenses, indicate they are only dispenses so should be
      // visualised differently in the UI.
      kind: multiDispenseIndex === 0 ? 'parallel_transfer' : 'parallel_dispense',
      channels,
      time_estimate: timeForEachDispense,
      cumulative_time_estimate:
        timeAtStartOfTransfer + timeForEachDispense * (multiDispenseIndex + 1),
    };
  });
}

function removeUninterestingSteps(expandedSteps: MixPreviewStep[]): MixPreviewStep[] {
  const indexOfLastStep = expandedSteps.length - 1;
  // We need to remove the steps here (not filter later in MixState), so that
  // the total number of steps goes down.
  return expandedSteps.filter((step, index) => {
    if (step.kind === 'prompt' && step.message === '___BARRIER___') {
      // The "___BARRIER___" is an artificial prompt that element developers use
      // as substitute for a better way to influence the ordering of actions
      // produced by the Antha scheduler.
      // There is no better way to detect this prompt than by checking the
      // message currently.
      return false;
    }
    if (step.kind === 'unload') {
      if (index === indexOfLastStep) {
        // The very last step is likely an 'unload'. We want to keep this single
        // 'unload' step at the very end, so that the time estimate of the last
        // step matches the time estimate of the Report object, shown in the
        // header.
        return true;
      } else {
        // The tip unload actions are not interesting for the user. Usually,
        // tips are simply dropped into the tipwaste. Even though the unload
        // action allows for tips to be returned back into a tipbox, Haydn says
        // that returning and reusing those tips would be a really bad practice,
        // and the antha-lang simulator doesn't do that.
        // Summary: Once a tip is loaded, we show it's missing from the box.
        // After a tip is loaded, from the user's perspective, that tip is gone.
        // It will be unloaded somewhere later, but we don't display this
        // because unloading doesn't matter to the user.
        return false;
      }
    }
    return true;
  });
}

export function getParallelTransferStageNameForStep(
  step: ParallelTransferStep,
): string | undefined {
  // The actions.json schema allows a stage name to be different for each channels. In
  // practice, all channels will have the same stage name. So just return the stage_name
  // for any channel.
  return Object.values(step.channels)[0]?.stage_name;
}

export function isParallelTransfer(step: MixPreviewStep): step is ParallelTransferStep {
  return step.kind === 'parallel_transfer';
}

export function hasParallelTransferStage(step: MixPreviewStep) {
  return isParallelTransfer(step) && !!getParallelTransferStageNameForStep(step);
}

/**
 * We assume here that manual actions required from user have no time estimates
 * while automated actions have specific time estimates.
 */
export function isAutomatedAction(step: MixPreviewStep) {
  return step.time_estimate > 0;
}
