import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';

import HelpIcon from 'client/app/icons/Help';
import { TOP_NAV_MENU_ID } from 'client/app/registry';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function BreadcrumbsDocsButton() {
  const onClickGuides = useCallback(() => {
    logEvent('open-help-and-docs', TOP_NAV_MENU_ID);
    window.open('https://intercom.help/antha/en', '_blank', 'noopener noreferrer');
  }, []);

  return (
    <Tooltip title="Help Center">
      <Help data-heap-tracking="breadcrumbs-docs-button" onClick={onClickGuides}>
        <HelpIcon />
      </Help>
    </Tooltip>
  );
}

const Help = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: theme.spacing(6),
  marginRight: theme.spacing(5),
}));
