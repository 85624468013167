import React, { useMemo } from 'react';

import Print from '@mui/icons-material/Print';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { DispenseChecklist, PlateInfo } from 'client/app/api/ChecklistApi';
import { usePlateType } from 'client/app/api/PlateTypesApi';
import { PlateContentsMatrix } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import LinearProgress from 'common/ui/components/LinearProgress';
import PlateLayout from 'common/ui/components/PlateLayout/PlateLayout';
import Popover from 'common/ui/components/Popover';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelectorWithPlateDimensions } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  checklist: DispenseChecklist;
};

export default function TaskChecklist({ checklist }: Props) {
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  return (
    <ChecklistContainer>
      <GridHeaderTypography variant="caption" sx={{ gridColumnStart: 2 }}>
        Input plates
      </GridHeaderTypography>
      <GridHeaderTypography variant="caption">Output plates</GridHeaderTypography>
      <GridHeaderTypography variant="caption">Execution file</GridHeaderTypography>
      {checklist.dispense_list_steps.map(step => (
        <GridRow key={step.dispense_list}>
          <ChecklistCounter />
          <SourcePlatesCell hasMultiplePlates={step.source_plates.length > 1}>
            {step.source_plates.map(plate => (
              <PlateNameWithTooltip
                key={plate.name}
                plate={plate}
                liquidColors={liquidColors}
              />
            ))}
          </SourcePlatesCell>
          <DestinationPlatesCell hasMultiplePlates={step.destination_plates.length > 1}>
            {step.destination_plates.map(plate => (
              <PlateNameWithTooltip
                key={plate.name}
                plate={plate}
                liquidColors={liquidColors}
              />
            ))}
          </DestinationPlatesCell>
          <ExecutionFileCell variant="body2">{step.dispense_list}</ExecutionFileCell>
        </GridRow>
      ))}
      <PrintSection>
        <PrintButton variant="text" endIcon={<PrintIcon />}>
          <Typography variant="body1" color="textPrimary">
            Print table and plate previews
          </Typography>
        </PrintButton>
      </PrintSection>
    </ChecklistContainer>
  );
}

function PlateNameWithTooltip({
  plate,
  liquidColors,
}: {
  plate: PlateInfo;
  liquidColors: LiquidColors;
}) {
  const { plateType, loading } = usePlateType(plate.plate_type);

  const plateName = (
    <PlateNameTypography variant="body2">{plate.name}</PlateNameTypography>
  );

  return !loading && plateType ? (
    <Popover
      title={
        <PlateTooltipTitle
          liquidColors={liquidColors}
          isPlateTypeFetchLoading={loading}
          plateType={plateType}
          wellContents={plate.well_contents}
        />
      }
    >
      {plateName}
    </Popover>
  ) : (
    plateName
  );
}

type PlateTooltipTitleProps = {
  plateType: PlateType | undefined;
  isPlateTypeFetchLoading: boolean;
  wellContents: PlateContentsMatrix;
  liquidColors: LiquidColors;
};

function PlateTooltipTitle({
  liquidColors,
  isPlateTypeFetchLoading,
  plateType,
  wellContents,
}: PlateTooltipTitleProps) {
  if (isPlateTypeFetchLoading || !plateType) {
    return <LinearProgress />;
  }
  const plateState: PlateState = {
    ...makeWellSelector(plateType),
    contents: wellContents,
  };
  const geometry =
    getLayoutForWellSelectorWithPlateDimensions(plateState).getCurrentGeometry(
      plateState,
    );

  const dimensions = geometry.getDimensions();

  return (
    <Svg
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
    >
      <PlateLayout geometry={geometry} plate={plateState} liquidColors={liquidColors} />
    </Svg>
  );
}

function ChecklistCounter() {
  return (
    <ChecklistCounterCell>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11.5" fill="white" stroke={Colors.GREY_30} />
      </svg>
    </ChecklistCounterCell>
  );
}

const ChecklistContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns:
    '[step-number] auto [input-plates] 1fr [output-plates] 1fr [execution-file] 3fr',
  gap: theme.spacing(4, 3),
  counterReset: 'checklist',
}));

const GridHeaderTypography = styled(Typography)({
  color: Colors.TEXT_SECONDARY,
});

const PlateNameTypography = styled(Typography)({
  textDecorationLine: 'underline',
  textDecorationStyle: 'dashed',
  textDecorationColor: Colors.TEXT_DISABLED,
  width: 'fit-content',
});

const Svg = styled('svg')({
  alignSelf: 'center',
  width: '100%',
});

const GridRow = styled('div')({
  display: 'grid',
  gridColumn: '1 / 5',
  gridTemplateColumns: 'subgrid',
  gridTemplateRows: 'auto 1fr',
});

const ChecklistCounterCell = styled('div')({
  display: 'grid',
  position: 'relative',
  '&::before': {
    counterIncrement: 'checklist',
    content: 'counter(checklist)',
    height: 24,
    width: 24,
    position: 'absolute',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',

    color: Colors.TEXT_SECONDARY,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.1px',
  },
});

const PlatesCell = styled('div')<{ hasMultiplePlates: boolean }>(
  ({ hasMultiplePlates }) => ({
    gridRow: hasMultiplePlates ? '1 / 3' : undefined,
    alignSelf: hasMultiplePlates ? undefined : 'center',
    cursor: 'default',
  }),
);

const SourcePlatesCell = styled(PlatesCell)<{ hasMultiplePlates: boolean }>(
  ({ hasMultiplePlates }) => ({
    gridColumn: hasMultiplePlates ? '2 / 3' : undefined,
  }),
);

const DestinationPlatesCell = styled(PlatesCell)<{ hasMultiplePlates: boolean }>(
  ({ hasMultiplePlates }) => ({
    gridColumn: hasMultiplePlates ? '3 / 4' : undefined,
  }),
);

const ExecutionFileCell = styled(TypographyWithTooltip)({
  alignSelf: 'center',
});

const PrintSection = styled('section')(({ theme }) => ({
  gridColumn: '1 / -1',

  borderTop: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(3),
}));

const PrintIcon = styled(Print)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 16,
  height: 16,
}));

const PrintButton = styled(MuiButton)({
  textTransform: 'none',
});
