import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';

import {
  EmptyBreadcrumbsItem,
  StatusBreadcrumbsItem,
} from 'client/app/apps/landing-page/status/StatusBreadcrumbsItem';
import {
  BreadcrumbsStep,
  PipelineStage,
} from 'client/app/apps/landing-page/status/types';
import { ApplicationStageEnum, BreadcrumbsEntityEnum } from 'client/app/gql';

type Props = {
  breadcrumbs: BreadcrumbsStep[];
  className?: string;
};

function getDefaultPipelineStage(entity: BreadcrumbsEntityEnum): PipelineStage {
  switch (entity) {
    case BreadcrumbsEntityEnum.EXPERIMENT:
    case BreadcrumbsEntityEnum.WORK_TREE:
      return 'experiment';
    case BreadcrumbsEntityEnum.CHERRY_PICKER:
    case BreadcrumbsEntityEnum.FORM:
    case BreadcrumbsEntityEnum.WORKFLOW:
      return 'design';
    case BreadcrumbsEntityEnum.SIMULATION:
      return 'plan';
    case BreadcrumbsEntityEnum.EXECUTION:
      return 'run';
    case BreadcrumbsEntityEnum.DATASET_DERIVATION:
      return 'process';
    case BreadcrumbsEntityEnum.VIS:
    case BreadcrumbsEntityEnum.VISUALISATION:
      return 'analyze';
  }
}

function getPipelineStage(breadcrumb: BreadcrumbsStep): PipelineStage | undefined {
  if (breadcrumb.items.length === 0) {
    return undefined;
  }

  const appStage = breadcrumb.items[0]?.appStage;

  if (appStage) {
    switch (appStage) {
      case ApplicationStageEnum.ANALYZE:
        return 'analyze';
      case ApplicationStageEnum.DESIGN:
        return 'design';
      case ApplicationStageEnum.PLAN:
        return 'plan';
      case ApplicationStageEnum.PROCESS:
        return 'process';
      case ApplicationStageEnum.RUN:
        return 'run';
    }
  }

  return getDefaultPipelineStage(breadcrumb.entity);
}

export default function StatusBreadcrumbs({ breadcrumbs, className }: Props) {
  const pipeline = useMemo(() => {
    const result: Record<PipelineStage, BreadcrumbsStep | undefined> = {
      experiment: undefined,
      design: undefined,
      plan: undefined,
      run: undefined,
      process: undefined,
      analyze: undefined,
    };

    for (const breadcrumb of breadcrumbs) {
      const stage = getPipelineStage(breadcrumb);

      if (stage) {
        result[stage] = breadcrumb;
      }
    }

    return result;
  }, [breadcrumbs]);

  return (
    <Container
      className={className}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      {(Object.keys(pipeline) as PipelineStage[]).map((stage, i) => {
        const bc = pipeline[stage];

        return bc ? (
          <StatusBreadcrumbsItem key={stage} breadcrumb={bc} position={i} />
        ) : stage === 'experiment' ? null : (
          <EmptyBreadcrumbsItem key={stage} stage={stage} position={i} />
        );
      })}
    </Container>
  );
}

const Container = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  position: 'relative',
  borderRadius: theme.spacing(3),
  overflow: 'hidden',
  cursor: 'default',
}));
