import { useMemo } from 'react';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { Identifiable } from 'client/app/lib/workflow/cloneWithUUID';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Connection, ElementInstance } from 'common/types/bundle';

export function buildElementContext(
  element: ElementInstance,
  elements: ElementInstance[],
  connections: Identifiable<Connection>[],
) {
  return {
    outputs: Object.fromEntries(
      Object.entries(element.Meta.outputs ?? {}).map(([param, value]) => {
        const parameter = element.element.outputs.find(p => p.name === param);
        return [param, { parameter, value }];
      }),
    ),
    inputs: Object.fromEntries(
      connections.flatMap(conn => {
        if (conn.Target.ElementInstance === element.name) {
          const source = elements.find(e => e.name === conn.Source.ElementInstance);
          if (source) {
            const parameter = element.element.inputs.find(
              p => p.name === conn.Target.ParameterName,
            );

            return [
              [
                conn.Target.ParameterName,
                {
                  parameter,
                  value: source.Meta.outputs?.[conn.Source.ParameterName],
                },
              ],
            ];
          }
        }

        return [];
      }),
    ),
  };
}

export function useElementContext() {
  const element = useElementInstance();
  const elements = useWorkflowBuilderSelector(state => state.elementInstances);
  const connections = useWorkflowBuilderSelector(state => state.InstancesConnections);
  const isSaving = useWorkflowBuilderSelector(state => state.isSaving);

  return useMemo(() => {
    if (!element) {
      return { loading: false, context: null };
    }

    return {
      loading: isSaving, // Loading completed when the workflow has saved
      context: buildElementContext(element, elements, connections),
    };
  }, [connections, element, elements, isSaving]);
}
