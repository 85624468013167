import React, { useCallback, useEffect, useMemo } from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import { useParameterContext } from 'client/app/components/Parameters/ParameterEditor';
import { defaultPlateAssignment } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { useInputPlates } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { PlateLayoutEditorAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { PlateAssignment, PlateNamesWithAssignment } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { useArrayEditorItemContext } from 'common/ui/components/ParameterEditors/ArrayEditor';
import TextField from 'common/ui/filaments/TextField';

type Props = {
  value: PlateNamesWithAssignment | null;
  onChange: (param: PlateNamesWithAssignment | null, instanceName?: string) => void;
  isDisabled?: boolean;
  configuration?: PlateLayoutEditorAdditionalProps;
};

export default function PlateLayoutParameter({
  value,
  onChange,
  isDisabled,
  configuration,
}: Props) {
  const dispatch = useWorkflowBuilderDispatch();
  const param = useParameterContext();
  const { allItems, index } = useArrayEditorItemContext<PlateNamesWithAssignment>();

  const isActive = useWorkflowBuilderSelector(
    state =>
      state.plateLayoutEditor?.entryId === value?.id &&
      state.additionalPanel === 'PlateLayoutEditor',
  );

  const isDuplicate = useMemo(
    () =>
      !!value &&
      allItems.some(
        (item, i) => item?.plateNames.includes(value.plateNames[0]) && i < index,
      ),
    [allItems, index, value],
  );

  const handleClick = () => {
    if (value?.plateNames.length && !!param) {
      dispatch({
        type: 'openPlateLayoutEditor',
        payload: {
          hasLiquidsInPlate: !configuration?.plateOptionsInput,
          paramName: param.name,
          entryId: value.id,
        },
      });
    }
  };

  useEffect(() => {
    if (value && !value.id) {
      onChange({
        ...value,
        id: uuid(),
      });
    }
  }, [onChange, value]);

  return (
    <Wrapper>
      {configuration?.plateOptionsInput ? (
        // TODO: Limit which plates can be selected so only those of the same type can be chosen.
        <SelectPlates
          value={value}
          isDisabled={isDisabled}
          plateOptionsInput={configuration.plateOptionsInput}
          onChange={onChange}
          allItems={allItems}
        />
      ) : (
        <PlateName
          value={value?.plateNames[0]}
          onChange={name => {
            onChange({
              id: value?.id ?? uuid(),
              plateNames: name ? [name] : [],
              plateAssignment:
                value?.plateAssignment ?? (defaultPlateAssignment() as PlateAssignment),
            });
          }}
          isDisabled={isDisabled}
          isDuplicate={isDuplicate}
        />
      )}
      <EditButton
        variant="secondary"
        startIcon={<ChevronLeftOutlinedIcon />}
        disabled={
          value?.plateNames === undefined || value.plateNames.length === 0 || isDuplicate
        }
        onClick={handleClick}
        active={isActive}
      >
        <Typography variant="body2">Edit Mix Layout</Typography>
      </EditButton>
    </Wrapper>
  );
}

function PlateName({
  isDisabled,
  value,
  onChange,
  isDuplicate,
}: {
  isDisabled?: boolean;
  value?: string;
  onChange: (value?: string) => void;
  isDuplicate: boolean;
}) {
  return (
    <>
      <TextField
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        placeholder="Plate Name"
        disabled={isDisabled}
        error={isDuplicate}
      />
      {isDuplicate ? <FormHelperText error>Duplicate plate name</FormHelperText> : null}
    </>
  );
}

function SelectPlates({
  isDisabled,
  value,
  plateOptionsInput,
  onChange,
  allItems,
}: {
  isDisabled?: boolean;
  value: PlateNamesWithAssignment | null;
  plateOptionsInput: string;
  onChange: (param: PlateNamesWithAssignment | null, instanceName?: string) => void;
  allItems: (PlateNamesWithAssignment | null)[];
}) {
  const { loading, inputPlates: plateOptions } = useInputPlates(plateOptionsInput);

  const selectablePlates = useMemo(() => {
    if (plateOptions) {
      // Don't let the user select a plate that is already being mixed onto.
      let result = plateOptions.filter(
        option =>
          !allItems.some(
            item => item !== value && item?.plateNames.includes(option.value),
          ),
      );

      // Don't let the user select plates of a different type to mix onto.
      if (value?.plateAssignment.plateType) {
        result = result.filter(
          option => option.plateType === value.plateAssignment.plateType,
        );
      }

      return result;
    }

    return plateOptions;
  }, [allItems, plateOptions, value]);

  const noOptions = !selectablePlates || selectablePlates.length === 0;

  const onSelected = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const plateName = Array.isArray(e.target.value)
        ? e.target.value[0]
        : e.target.value;
      const option = plateName
        ? plateOptions?.find(o => o.value === plateName)
        : undefined;

      if (option) {
        const plateAssignment: PlateAssignment = {
          ...defaultPlateAssignment(true),
          ...value?.plateAssignment,
          plateType: option.plateType,
        };

        onChange({
          id: value?.id ?? uuid(),
          plateNames: Array.isArray(e.target.value) ? e.target.value : [e.target.value],
          plateAssignment,
        });
      } else {
        onChange(null);
      }
    },
    [onChange, plateOptions, value?.id, value?.plateAssignment],
  );

  return (
    <Select
      multiple
      value={value?.plateNames ?? []}
      displayEmpty
      onChange={onSelected}
      size="small"
      renderValue={selected => (
        <MenuBox>
          {loading && noOptions ? (
            <Typography color="textSecondary">Loading...</Typography>
          ) : !loading && noOptions ? (
            <Typography color="textSecondary">No plates found...</Typography>
          ) : selected.length === 0 ? (
            <Typography color="textSecondary">Plates to mix onto...</Typography>
          ) : (
            selected.map(value => <Chip key={value} label={value} size="small" />)
          )}
        </MenuBox>
      )}
      disabled={isDisabled || noOptions}
    >
      {selectablePlates?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

const EditButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<{
  active: boolean;
}>(({ active, theme }) =>
  active
    ? {
        background: Colors.BLUE_5,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      }
    : {},
);
