import { useMemo } from 'react';

import { getDeviceModelLabel } from 'client/app/api/deviceFromGraphql';
import { DeviceThumbnailFragment, Simulation, SimulationStage } from 'client/app/gql';
import { ServerSideBundle } from 'common/types/bundle';
import { ensureV3Config } from 'common/types/bundleTransforms';
import { StageDetails } from 'common/types/mixPreview';

/**
 * Composes `StageDetails` for each stage of the simulation:
 * - stage name set by user in Builder
 * - device used in this stage
 *
 * Sorted by stage order in the simulation.
 */
export default function useStageDetails(simulation?: Simulation): StageDetails[] {
  return useMemo(() => {
    const result: StageDetails[] = [];

    if (!simulation) return result;

    simulation.stages.forEach(stage => {
      // Assignment using orderNum keeps the natural order of stages in the simulation
      result[stage.orderNum] = {
        name: stage.name,
        device: {
          ...mapToStageDevice(stage.mainDevice),
          accessibleDevices: stage.peripheralDevices.map(mapToStageDevice),
          runConfig: getStageRunConfig(stage, simulation.workflow.workflow),
        },
      };
    });

    return result;
  }, [simulation]);
}

function mapToStageDevice(device: DeviceThumbnailFragment) {
  return {
    id: device.id,
    name: device.name,
    class: device.model.anthaLangDeviceClass,
    label: getDeviceModelLabel(device),
    imageUrl: device.model.pictureURL,
  };
}

/**
 * Returns device run configuration of the main device of simulation stage
 */
export function getStageRunConfig(
  stage: SimulationStage,
  bundle: ServerSideBundle,
): string | undefined {
  const workflow = ensureV3Config(bundle);

  const configuredDevice = workflow.Config.configuredDevices?.find(
    cd => cd.deviceId === stage.mainDevice.id,
  );
  const runConfig = stage.mainDevice.runConfigs.find(
    config => config.id === configuredDevice?.runConfigId,
  );
  return runConfig?.name;
}
