import React, { useCallback } from 'react';

import { UserProfileType } from 'client/app/hooks/useUserProfile';
import useIntercomService from 'client/app/lib/intercomServiceHook';
import { TOP_NAV_MENU_ID } from 'client/app/registry';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';

// We need a unique ID. Here we can safely assume there will never be more than one Intercom Launcher.
const customLauncherId = 'intercomCustomLauncher';

type Props = {
  userProfile: UserProfileType;
};

export default function BreadcrumbsIntercomButton({ userProfile }: Props) {
  useIntercomService(userProfile, true, customLauncherId);

  // If the appId was unset, we should fallback for the user to be able to email Synthace support.
  const onClick = useCallback(() => {
    logEvent('open-intercom', TOP_NAV_MENU_ID);
    return userProfile.intercomAppId
      ? `mailto:${userProfile.intercomAppId}@intercom-mail.com`
      : `mailto:support@synthace.com`;
  }, [userProfile]);

  return (
    <Tooltip title="Talk to Us">
      <span
        data-heap-tracking="breadcrumbs-intercom-button"
        id={customLauncherId}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <TalkToUsIcon />
      </span>
    </Tooltip>
  );
}
