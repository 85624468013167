import React, { ExoticComponent } from 'react';

import { styled } from '@mui/material/styles';

import {
  BreadcrumbsStep,
  PipelineStage,
} from 'client/app/apps/landing-page/status/types';
import useBreadcrumbsEntity from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import {
  DataAnalysisIcon,
  ExecutionIcon,
  NewExperimentsIcon,
  SimulationIcon,
  WorkflowIcon,
} from 'common/ui/icons';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';

export function StatusBreadcrumbsItem({
  breadcrumb,
  position,
}: {
  breadcrumb: BreadcrumbsStep;
  position: number;
}) {
  const { EntityIcon, color, navigateToEntity, title } = useBreadcrumbsEntity(breadcrumb);

  return (
    <Tooltip
      title={title ? title : breadcrumb.items[0]?.name}
      PopperProps={{ sx: { '& .MuiTooltip-tooltip': { paddingX: 4, paddingY: 3 } } }}
    >
      <ActiveItem
        style={{ zIndex: 6 - position }}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          navigateToEntity(breadcrumb.items[0]);
        }}
      >
        <EntityIcon style={{ color }} />
      </ActiveItem>
    </Tooltip>
  );
}

const DEFAULT_ICONS: Record<PipelineStage, ExoticComponent> = {
  experiment: NewExperimentsIcon,
  design: WorkflowIcon,
  plan: SimulationIcon,
  run: ExecutionIcon,
  process: DataSetIcon,
  analyze: DataAnalysisIcon,
};

export function EmptyBreadcrumbsItem({
  stage,
  position,
}: {
  stage: PipelineStage;
  position: number;
}) {
  const Icon = DEFAULT_ICONS[stage];

  return (
    <Item style={{ color: Colors.GREY_30, zIndex: 6 - position }}>
      <Icon />
    </Item>
  );
}

// This is a TypeScript hack necessary to let `Item` and `ActiveItem` reference each other.
const styledDiv = styled('div');
type StyledDiv = ReturnType<typeof styledDiv>;

const Item: StyledDiv = styled('div')`
  background: ${Colors.GREY_10};
  position: relative;
  padding: 6px 12px 6px 32px;
  margin-left: -11px;
  display: flex;
  align-items: center;
  border-color: ${Colors.GREY_20};
  border-style: solid;
  border-width: 1px 0;
  &:first-of-type {
    margin-left: 0;
    padding-left: 16px;
    border-radius: ${({ theme }) => theme.spacing(3, 0, 0, 3)};
    border-left-width: 1px;
  }
  &:last-of-type {
    border-right-width: 1px;
    border-radius: ${({ theme }) => theme.spacing(0, 3, 3, 0)};
  }
  &:not(:last-of-type)::before {
    position: absolute;
    right: -10px;
    top: 50%;
    content: '';
    width: 19px;
    height: 19px;
    background: ${Colors.GREY_10};
    border-right: 2px solid ${Colors.GREY_20};
    border-top: 2px solid ${Colors.GREY_20};
    transform: translateY(-50%) matrix(0.42, 0.99, -0.42, 0.99, 0, 0);
    transform-origin: center;
    z-index: -1;
  }
  &:has(+ ${() => ActiveItem})::before {
    border-color: ${Colors.GREY_30};
  }
  &:has(+ ${() => ActiveItem}:hover)::before {
    border-color: ${Colors.BLUE_20};
  }
`;

const ActiveItem: StyledDiv = styled(Item)`
  background: ${Colors.WHITE};
  border-color: ${Colors.GREY_30};
  cursor: pointer;
  &:not(:last-of-type)::before {
    background: ${Colors.WHITE};
    border-color: ${Colors.GREY_30};
    cursor: pointer;
  }
  &:hover {
    background: ${Colors.BLUE_5};
    border-color: ${Colors.BLUE_20};
    &:not(:last-of-type)::before {
      background: ${Colors.BLUE_5};
      border-color: ${Colors.BLUE_20};
    }
  }
  &:has(+ ${() => ActiveItem}:hover)::before {
    border-color: ${Colors.BLUE_20};
  }
`;
