import { styled } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Colors from 'common/ui/Colors';

const Toggle = styled(ToggleButtonGroup)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.paper,
  borderColor: Colors.GREY_30,
  boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.1)`,
}));

export const ToggleButton = styled(MuiToggleButton)(
  ({ theme: { typography, palette, spacing } }) => ({
    color: palette.text.primary,
    ...typography.body1,
    textTransform: 'none',
    padding: spacing(3, 4),
    flex: 1,
    '&.MuiToggleButtonGroup-grouped.Mui-selected': {
      color: palette.primary.dark,
      backgroundColor: Colors.BLUE_0,
      border: `1px solid ${Colors.BLUE_20}`,
      ...typography.subtitle2,
      '&:hover': {
        backgroundColor: Colors.BLUE_5,
      },
    },
    '&.Mui-disabled': {
      color: palette.text.disabled,
    },
  }),
);

export default Toggle;
