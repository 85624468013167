import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  QUERY_RECENT_WORKFLOWS,
  QUERY_WORKFLOW_STATUS_BREADCRUMBS,
} from 'client/app/api/gql/queries';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import StatusBreadcrumbs from 'client/app/apps/landing-page/status/StatusBreadcrumbs';
import {
  RecentWorkflowsQuery,
  WorkflowSourceEnum,
  WorkflowStatusBreadcrumbsQuery,
} from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

const RECENT_WORKFLOW_COUNT = 5;

type Status = WorkflowStatusBreadcrumbsQuery['workflowStatusBreadcrumbs'][0]['status'];

export default function RecentSection() {
  const userProfile = useUserProfile();

  const recentQuery = useQuery(QUERY_RECENT_WORKFLOWS, {
    variables: {
      userId: userProfile?.id,
      count: RECENT_WORKFLOW_COUNT,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !userProfile,
  });

  recentQuery.data;

  const statuses = useWorkflowStatuses(recentQuery.data);

  return (
    <Workflows>
      <RenderQuery
        query={recentQuery}
        additionalDataProps={{
          statuses,
        }}
        renderData={RenderData}
        renderNoData={NoEntitiesMessage}
        additionalNoDataProps={{
          entityName: 'workflows' as const,
          messageType: MessageType.NO_FILTER_RESULTS,
        }}
        loadingComponent={Loading}
      />
    </Workflows>
  );
}

const Loading = () => (
  <>
    {Array.from({ length: RECENT_WORKFLOW_COUNT }).map((_, i) => (
      <WorkflowItem key={`${i}`}>
        <ItemSkeleton variant="rounded" />
      </WorkflowItem>
    ))}
  </>
);

function RenderData({
  data,
  statuses,
}: {
  data: RecentWorkflowsQuery;
  statuses: { byWorkflowId?: Record<string, Status>; loading: boolean };
}) {
  const navigation = useNavigation();

  return (
    <>
      {data.workflows.items.map(wf => (
        <WorkflowItem
          onClick={() =>
            navigation.navigate(getWorkflowLink(wf.source), { workflowId: wf.id })
          }
          key={wf.id}
        >
          <Title variant="h5" color="textPrimary">
            {wf.name}
          </Title>
          <LastModified variant="caption" color="textSecondary">
            {formatDateTime(new Date(wf.lastModifiedAt))}
          </LastModified>
          {statuses.loading ? (
            <StatusSkeleton variant="rounded" />
          ) : statuses.byWorkflowId?.[wf.id] ? (
            <Status breadcrumbs={statuses.byWorkflowId[wf.id]} />
          ) : null}
        </WorkflowItem>
      ))}
    </>
  );
}

function getWorkflowLink(source: WorkflowSourceEnum) {
  switch (source) {
    case WorkflowSourceEnum.CHERRY_PICKER:
      return workflowRoutes.openInCherryPicker;
    case WorkflowSourceEnum.FORM_EDITOR:
      return workflowRoutes.editForm;
    default:
      return workflowRoutes.openInWorkflowBuilder;
  }
}

function useWorkflowStatuses(recent: RecentWorkflowsQuery | undefined) {
  const { data, loading } = useQuery(QUERY_WORKFLOW_STATUS_BREADCRUMBS, {
    skip: recent === undefined,
    variables: {
      workflowIds: recent?.workflows.items.map(({ id }) => id) ?? [],
    },
  });

  const byWorkflowId = useMemo(() => {
    return data
      ? Object.fromEntries(
          data.workflowStatusBreadcrumbs.map(item => [item.workflowId, item.status]),
        )
      : undefined;
  }, [data]);

  return { byWorkflowId, loading };
}

const Workflows = styled('div')({
  listStyleType: 'none',
  position: 'relative',
});

const Title = styled(Typography)({
  gridArea: 'title',
});

const LastModified = styled(Typography)({
  gridArea: 'modified',
});

const Status = styled(StatusBreadcrumbs)({
  gridArea: 'status',
  alignSelf: 'center',
});

const StatusSkeleton = styled(Skeleton)({
  gridArea: 'status',
  height: 30,
  width: 300,
  alignSelf: 'center',
});

const WorkflowItem = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(5, 0),
  display: 'grid',
  gridTemplate: `
    'title status' auto
    'modified status' auto / 1fr auto
  `,
  flexDirection: 'column',
  gap: theme.spacing(3),
  zIndex: 0,
  cursor: 'pointer',

  '&:not(:last-child)': {
    borderBottom: `1px solid ${Colors.GREY_20}`,
  },
  textDecoration: 'none',
  '&:hover,&:focus': {
    outline: 0,
    '::before': {
      content: '""',
      position: 'absolute',
      inset: `${theme.spacing(2)} -${theme.spacing(4)}`,
      background: Colors.BLUE_0,
      borderRadius: 8,
      zIndex: -1,
    },

    [`${Title}`]: {
      color: Colors.PRIMARY_MAIN,
    },
  },
  ':focus::before': {
    outline: `2px solid ${Colors.PRIMARY_MAIN}`,
  },
}));

const ItemSkeleton = styled(Skeleton)({
  height: 44,
  width: '100%',
  gridArea: '1 / 1 / 3 / 2',
});
