import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import {
  Redirect,
  Route,
  RouteChildrenProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import CherryPickScreen from 'client/app/apps/cherry-picker/CherryPickRouting';
import DOETemplateScreen from 'client/app/apps/doe-template-editor/DOETemplateScreen';
import ExecutionDetailsScreen from 'client/app/apps/execution-details/ExecutionDetailsScreen';
import ExperimentDetailRouter from 'client/app/apps/experiments/ExperimentDetailRoute';
import { ExperimentRouter } from 'client/app/apps/experiments/ExperimentRouter';
import FeatureTogglesScreen from 'client/app/apps/feature-toggles/FeatureTogglesApp';
import FileBrowserScreen from 'client/app/apps/file-browser/FileBrowserApp';
import LandingPageSceen from 'client/app/apps/landing-page/LandingPageScreen';
import LocalDevScreen from 'client/app/apps/local-dev/LocalDevApp';
import MarkdownDevScreen from 'client/app/apps/markdown-dev/MarkdownDevApp';
import PlateLibraryScreen from 'client/app/apps/plate-library/PlateLibraryApp';
import PolicyLibraryScreen from 'client/app/apps/policy-library/PolicyLibraryApp';
import ProfileScreen from 'client/app/apps/profile/ProfileApp';
import SimulationDetailsScreen from 'client/app/apps/simulation-details/SimulationDetailsScreen';
import TemplateWorkflowsScreen from 'client/app/apps/template-workflows/TemplateWorkflowsApp';
import ResponseAnalysisScreen from 'client/app/apps/Visualization/ResponseAnalysisApp';
import VisualizationScreen from 'client/app/apps/Visualization/VisualizationApp';
import VisViewScreen from 'client/app/apps/Visualization/VisViewApp';
import { workTreeRoute, WorkTreeScreen } from 'client/app/apps/work-tree/WorkTree';
import WorkflowScreen from 'client/app/apps/workflow-builder/WorkflowScreen';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import { getSynthaceDomainURL } from 'client/app/components/AppRouter/synthacebio';
import DeviceLibraryScreen from 'client/app/components/DeviceLibrary/DeviceLibraryApp';
import { withNavigationSidepanel } from 'client/app/components/NavigationSidepanel';
import { doeTemplateRoutes, templateWorkflowRoutes } from 'client/app/lib/nav/actions';
import { LegacyScreenRegistry, ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Screen404 from 'common/ui/components/Screen404';
import { ScreenID } from 'common/ui/navigation';

// Create the router which require sidepanel outside of `AppRouter` to avoid new instances being created on each render of `AppRouter`
// which can result in unmounting and remounting of the same component
const FileBrowserScreenWithSidePanel = withNavigationSidepanel(FileBrowserScreen);
const PlateLibraryScreenWithSidePanel = withNavigationSidepanel(PlateLibraryScreen);
const PolicyLibraryScreenWithSidePanel = withNavigationSidepanel(PolicyLibraryScreen);
const DeviceLibraryScreenWithSidePanel = withNavigationSidepanel(DeviceLibraryScreen);

const AppRouter = () => {
  const isSynthaceBioRedirectEnabled = useFeatureToggle('SYNTHACE_BIO_REDIRECT');
  useEffect(() => {
    if (isSynthaceBioRedirectEnabled) {
      // During migration to synthace.io domain we'll enable this redirect for
      // environments which should use synthace.io domain exclusively (which were
      // already migrated). Few months later we'll remove this code and replace it
      // with some configuration in the infrastructure which will respond with
      // 308 Permanent Redirect to every request, without loading the UI.
      const newUrl = getSynthaceDomainURL(new URL(window.location.href));
      if (newUrl) {
        // redirect to the newUrl
        window.location.replace(newUrl);
      }
    }
  }, [isSynthaceBioRedirectEnabled]);

  const isEnabledLandingPage = useFeatureToggle('LANDING_PAGE');

  return (
    <Switch>
      {isEnabledLandingPage ? (
        <ScreenRoute
          path="/"
          exact
          tabTitle="Home"
          component={LandingPageSceen}
          screenId={ScreenRegistry.HOME}
        />
      ) : (
        <Route
          path="/"
          exact
          render={() => <Redirect to={`/${ScreenRegistry.EXPERIMENTS}`} />}
        />
      )}

      <ScreenRoute
        path={`/${ScreenRegistry.FILE_BROWSER}`}
        tabTitle="Files"
        component={FileBrowserScreenWithSidePanel}
        screenId={ScreenRegistry.FILE_BROWSER}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.CHERRY_PICKER}`}
        tabTitle="Cherry Picker"
        component={CherryPickScreen}
        screenId={ScreenRegistry.CHERRY_PICKER}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.LOCAL_DEV}`}
        tabTitle="Local Mix Preview"
        component={LocalDevScreen}
        screenId={ScreenRegistry.LOCAL_DEV}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.MARKDOWN_DEV}`}
        tabTitle="Markdown Preview"
        component={MarkdownDevScreen}
        screenId={ScreenRegistry.MARKDOWN_DEV}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.DEVICE_LIBRARY}`}
        tabTitle="Devices"
        component={DeviceLibraryScreenWithSidePanel}
        screenId={ScreenRegistry.DEVICE_LIBRARY}
      />
      {/* Redirect from legacy URL (`antha-device-library`) to new ones. */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_DEVICE_LIBRARY}`}
        render={() => <Redirect to={`/${ScreenRegistry.DEVICE_LIBRARY}`} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.DOE_TEMPLATE}`}
        tabTitle="DOE Template Editor"
        component={DOETemplateScreen}
        screenId={ScreenRegistry.DOE_TEMPLATE}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.DOE_DESIGN}`}
        tabTitle="DOE Design"
        component={DOETemplateScreen}
        screenId={ScreenRegistry.DOE_DESIGN}
      />
      {/* Redirect from legacy URL for DOE design to new one. */}
      <Route
        path={`/${ScreenRegistry.DOE_TEMPLATE}/:id/design`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={doeTemplateRoutes.design.getPath({ workflowId: id })} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.FEATURE_TOGGLE}`}
        tabTitle="Feature Toggles"
        component={FeatureTogglesScreen}
        screenId={ScreenRegistry.FEATURE_TOGGLE}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.SIMULATION_DETAILS}`}
        tabTitle="Simulation Details"
        component={SimulationDetailsScreen}
        screenId={ScreenRegistry.SIMULATION_DETAILS}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.EXECUTION_DETAILS}`}
        tabTitle="Execution Details"
        component={ExecutionDetailsScreen}
        screenId={ScreenRegistry.EXECUTION_DETAILS}
      />
      {/* Redirect from legacy URLs (`antha-jobs`) to new ones. */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_EXECUTION_DETAILS}`}
        render={({ location: { pathname } }) => (
          <Redirect
            to={`${pathname.replace(
              `${LegacyScreenRegistry.LEGACY_EXECUTION_DETAILS}`,
              `${ScreenRegistry.SIMULATION_DETAILS}`,
            )}`}
          />
        )}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.TEMPLATE_WORKFLOWS}`}
        tabTitle="Template Builder"
        component={TemplateWorkflowsScreen}
        screenId={ScreenRegistry.TEMPLATE_WORKFLOWS}
      />
      {/* Handle the legacy  "Pinned Workflow" app (standalone list). This now lives in
      the experiments page, accessed by clicking on "Templates" button. */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_TEMPLATE_WORKFLOWS}`}
        exact
        render={() => <Redirect to={`/${ScreenRegistry.EXPERIMENTS}`} />}
      />
      {/* Redirect legacy URLs (`pinned-workflow/workflows/` and variants)
        to new ones. */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_TEMPLATE_WORKFLOWS}`}
        render={({ location: { pathname } }) => (
          <Redirect
            to={`${pathname.replace(
              `${LegacyScreenRegistry.LEGACY_TEMPLATE_WORKFLOWS}/workflows`,
              `${ScreenRegistry.FORM}`,
            )}`}
          />
        )}
      />
      {/* Handle redirecting legacy URL that pointed to a what we now call "Template".
      There are two versions of this: a deprecated one (edit/:id) and another one (:id/edit) */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_TEMPLATE_WORKFLOWS}/edit/:id/`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={templateWorkflowRoutes.editTemplate.getPath({ id })} />}
      />
      {/* Handle redirecting legacy URL that pointed to a what we now call "Template" */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_TEMPLATE_WORKFLOWS}/:id/edit`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={templateWorkflowRoutes.editTemplate.getPath({ id })} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.FORM}`}
        tabTitle="Form"
        component={TemplateWorkflowsScreen}
        screenId={ScreenRegistry.FORM}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.PLATE_TYPE_LIBRARY}`}
        tabTitle="Plate Types"
        component={PlateLibraryScreenWithSidePanel}
        screenId={ScreenRegistry.PLATE_TYPE_LIBRARY}
      />
      {/* Redirect from legacy URL (`antha-plate-type-library`) to new ones. */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_PLATE_TYPE_LIBRARY}`}
        render={() => <Redirect to={`/${ScreenRegistry.PLATE_TYPE_LIBRARY}`} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.POLICY_LIBRARY}`}
        tabTitle="Liquid Policies"
        component={PolicyLibraryScreenWithSidePanel}
        screenId={ScreenRegistry.POLICY_LIBRARY}
      />
      {/* Redirect from legacy URL (`antha-policy-library`) to new ones. */}
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_POLICY_LIBRARY}`}
        render={() => <Redirect to={`/${ScreenRegistry.POLICY_LIBRARY}`} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.RESPONSE_ANALYSIS}`}
        tabTitle="Response Analysis"
        component={ResponseAnalysisScreen}
        screenId={ScreenRegistry.RESPONSE_ANALYSIS}
      />
      <Route
        path={`/${LegacyScreenRegistry.LEGACY_SIMULATIONS}`}
        render={() => <Redirect to={`/${ScreenRegistry.EXPERIMENTS}`} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.VISUALIZATION}`}
        // No `tabTitle` in this route, as one of Visualization
        // component's children dynamically assigns it.
        component={VisualizationScreen}
        screenId={ScreenRegistry.VISUALIZATION}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.VIS_VIEW}/view`}
        component={VisViewScreen}
        screenId={ScreenRegistry.VIS_VIEW}
      />
      {/* Redirect from the legacy workflow dashboard (which used to be at /workflow) to experiments screen. */}
      <Route
        path={`/${ScreenRegistry.WORKFLOW}`}
        exact
        render={() => <Redirect to={`/${ScreenRegistry.EXPERIMENTS}`} />}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.WORKFLOW}`}
        tabTitle="Builder"
        component={WorkflowScreen}
        screenId={ScreenRegistry.WORKFLOW}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.EXPERIMENTS}`}
        component={ExperimentRouter}
        screenId={ScreenRegistry.EXPERIMENTS}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.EXPERIMENT_DETAIL}/:experimentID`}
        tabTitle="Experiment - Report"
        component={ExperimentDetailRouter}
        screenId={ScreenRegistry.EXPERIMENT_DETAIL}
      />
      <ScreenRoute
        path={`/${ScreenRegistry.PROFILE}`}
        tabTitle="User Management"
        component={ProfileScreen}
        screenId={ScreenRegistry.PROFILE}
      />
      <ScreenRoute
        path={workTreeRoute.pathTemplate}
        tabTitle="Experiment - Map"
        component={WorkTreeScreen}
        screenId={ScreenRegistry.WORK_TREE}
      />
      {/* Legacy routes used to start with /app. In case someone still uses the old routes, let's
            just redirect them to the modern one */}
      <Route
        path="/app"
        render={(props: RouteChildrenProps<any>) => (
          <Redirect to={props.location.pathname.replace('/app', '')} />
        )}
      />
      {/* If none of the above Routes was triggered (i.e. we didn't recognize the URL),
        show the 404 page. This catch-all route *must* be at the bottom of the Switch. */}
      <Route render={() => <Screen404 />} />
    </Switch>
  );
};

type RouteProps = {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
  tabTitle?: string;
  screenId: ScreenID;
};

function ScreenRoute(routeProps: RouteProps) {
  const { screenId } = routeProps;
  const screenContextValue = useMemo(
    () => ({
      screenId,
    }),
    [screenId],
  );
  return (
    <Route path={routeProps.path} exact={routeProps.exact}>
      <ScreenContext.Provider value={screenContextValue}>
        <AppContainer tabTitle={routeProps.tabTitle}>
          <routeProps.component />
        </AppContainer>
      </ScreenContext.Provider>
    </Route>
  );
}

type Props = PropsWithChildren<{ tabTitle?: string }>;

const AppContainer = (props: Props) => {
  const { tabTitle } = props;
  useEffect(() => {
    // Update the document title using the browser API.
    if (tabTitle) {
      document.title = tabTitle;
    }
  }, [tabTitle]);
  return <>{props.children}</>;
};

export default withRouter(AppRouter);
