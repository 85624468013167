import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import cx from 'classnames';

import { roundNumber } from 'common/lib/format';
import { WellContents } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import {
  RectPixels,
  WELL_BORDER_WIDTH_PX,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import WellLabel, {
  WellLabelContent,
} from 'common/ui/components/simulation-details/mix/WellLabel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export enum WellHighlightMode {
  SELECTION = 1,
  AVAILABILITY = 2,
}

export type Props = {
  wellPos: RectPixels;
  /**
   * WellType are the possible types we receive from the MixPreview - Used in the Simulation Details.
   * LegacyWellShape are the possible types of plates stored in the UI - Used e.g. in the Plate
   * builder, Cherry Picker, Plate Library.
   * The LegacyWellShape should go away at some point.
   * */
  wellType: string;
  wellContents?: WellContents;
  showContentLabel?: boolean;
  contentLabelOverride?: WellLabelContent;
  color?: string;
  strokeColor?: string;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  showEmptyWellsAsPossiblyAllocated?: boolean;
  highlightMode?: WellHighlightMode;
  /**
   * If hasError is true, and isHighlighted is true, we will show the well in an error state.
   * (i.e. error state is only shown on wells that are highlighted, not on e.g. non-highlighted wells)
   */
  hasError?: boolean;
  disableHoverState?: boolean;
  isFaded?: boolean;
};

export const isWellTypeCircular = (wellType: string) =>
  wellType.toLocaleUpperCase() === 'CYLINDER' ||
  wellType.toLocaleUpperCase() === 'CIRCLE';

function formatWellContentLabel(contents?: WellContents): WellLabelContent {
  return {
    type: 'ContentLabel',
    heading: contents?.total_volume ? roundNumber(contents.total_volume.value) : '',
    subHeading: contents?.total_volume?.unit,
  };
}

export const Well = React.memo(function Well({
  wellPos,
  wellType,
  wellContents,
  color,
  showContentLabel,
  isHighlighted,
  isDisabled,
  showEmptyWellsAsPossiblyAllocated,
  hasError,
  disableHoverState,
  contentLabelOverride,
  strokeColor,
  highlightMode = WellHighlightMode.SELECTION,
  isFaded,
}: Props) {
  const classes = useStyles();
  const isWellCircular = isWellTypeCircular(wellType);
  const wellIsEmpty = isWellEmpty(wellContents);
  const showWellHoverState = !isDisabled && !disableHoverState;
  // Calculate coordinates of the line going from top right to bottom left of
  // the well, where d is diameter:
  //  x1 = d/2 + sin(45deg) * d/2 = ~0.85d
  //  y1 = d/2 - cos(45deg) * d/2 = ~0.15d
  const disabledLine = useMemo(
    () => ({
      x1: isWellCircular ? 0.85 * wellPos.width : wellPos.width,
      y1: isWellCircular ? 0.15 * wellPos.height : 0,
      x2: isWellCircular ? 0.15 * wellPos.width : 0,
      y2: isWellCircular ? 0.85 * wellPos.height : wellPos.height,
    }),
    [isWellCircular, wellPos.height, wellPos.width],
  );

  const isPossiblyAllocated = wellIsEmpty && showEmptyWellsAsPossiblyAllocated;

  const label = useMemo<WellLabelContent>(() => {
    return isPossiblyAllocated
      ? { heading: '?', type: 'PossiblyAllocated' }
      : contentLabelOverride ?? formatWellContentLabel(wellContents);
  }, [contentLabelOverride, isPossiblyAllocated, wellContents]);

  const showAvailability = highlightMode === WellHighlightMode.AVAILABILITY;

  const shouldShowContentLabel =
    !showAvailability && (isPossiblyAllocated || showContentLabel);

  return (
    <g
      transform={`translate(${wellPos.left},${wellPos.top})`}
      opacity={isFaded ? 0.5 : 1}
    >
      <rect
        width={wellPos.width}
        height={wellPos.height}
        rx={isWellCircular ? wellPos.width / 2 : 0}
        ry={isWellCircular ? wellPos.height / 2 : 0}
        fill={color ?? Colors.GREY_0}
        stroke={strokeColor ?? Colors.WELL_BORDER}
        vectorEffect="non-scaling-stroke"
        className={cx(classes.well, {
          [classes.selected]: !showAvailability && isHighlighted && !hasError,
          [classes.available]: showAvailability && isHighlighted,
          [classes.unavailable]: showAvailability && !isHighlighted,
          [classes.disabled]: isDisabled,
          [classes.filledHoverState]: !wellIsEmpty && showWellHoverState,
          [classes.emptyHoverState]: wellIsEmpty && showWellHoverState,
          [classes.error]: isHighlighted && hasError,
          [classes.isPossiblyAllocated]: isPossiblyAllocated,
        })}
        // Used to identify the click target in the MixScreen
        data-well="true"
      />
      {isDisabled && !isHighlighted && (
        <line {...disabledLine} className={classes.disabledLine} />
      )}
      {showAvailability && (
        <WellAvailabilityIcon isAvailable={!!isHighlighted} wellPos={wellPos} />
      )}
      {shouldShowContentLabel && <WellLabel label={label} wellPos={wellPos} />}
    </g>
  );
});

export function isWellEmpty(wellContents: WellContents | undefined) {
  return !wellContents;
}

const useStyles = makeStylesHook(({ palette }) => ({
  well: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    vectorEffect: 'non-scaling-stroke',
  },
  emptyHoverState: {
    '&:hover': {
      fill: Colors.GREY_20,
      fillOpacity: 1,
    },
  },
  filledHoverState: {
    '&:hover': {
      fillOpacity: 0.5,
    },
  },
  disabled: {
    stroke: Colors.WELL_BORDER,
  },
  disabledLine: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    vectorEffect: 'non-scaling-stroke',
    stroke: Colors.WELL_BORDER,
  },
  error: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: Colors.ERROR_MAIN,
  },
  selected: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: palette.primary.main,
  },
  available: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: palette.success.main,
  },
  unavailable: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: palette.grey[200],
  },
  isPossiblyAllocated: {
    stroke: palette.grey[200],
  },
}));

function WellAvailabilityIcon({
  isAvailable,
  wellPos,
}: {
  isAvailable: boolean;
  wellPos: RectPixels;
}) {
  const size = Math.min(wellPos.width * 0.5, wellPos.height * 0.5);
  const left = (wellPos.width - size) / 2;
  const top = (wellPos.height - size) / 2;
  const scale = size / 10;

  return (
    <WellAvailabilityWrapper
      transform={`translate(${left} ${top}) scale(${scale} ${scale})`}
    >
      {isAvailable ? (
        <path
          d="M3.17794 7.01478L0.807277 4.64412L0 5.44571L3.17794 8.62365L10 1.80159L9.19841 1L3.17794 7.01478Z"
          fill={Colors.SUCCESS_MAIN}
        />
      ) : (
        <path
          d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
          fill={Colors.GREY_30}
        />
      )}
    </WellAvailabilityWrapper>
  );
}

const WellAvailabilityWrapper = styled('g')({
  pointerEvents: 'none',
});
