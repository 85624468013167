import React, { useCallback, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';

import { formatWellPosition } from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import AxisLabels from 'common/ui/components/simulation-details/mix/AxisLabels';
import { getWellContentsHelper } from 'common/ui/components/simulation-details/mix/deckContents';
import { DeckItemWithWellsGeometry } from 'common/ui/components/simulation-details/mix/DeckLayout';
import InteractiveWell from 'common/ui/components/simulation-details/mix/InteractiveWell';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import {
  isWellEmpty,
  WellHighlightMode,
} from 'common/ui/components/simulation-details/mix/Well';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import {
  LegacyWellTooltipTitle,
  WellTooltipTitleProps,
} from 'common/ui/components/simulation-details/mix/WellTooltip';

export type Props = {
  geometry: DeckItemWithWellsGeometry;
  plate: PlateState;
  liquidColors: LiquidColors;
  /**
   * Wells to visually outline on the plate
   */
  highlightedWells?: readonly WellLocationOnDeckItem[];
  /**
   * Wells to show in a disabled state on the plate with disabled interactions.
   * Tooltips will still appear on disabled wells.
   */
  disabledWells?: readonly WellLocationOnDeckItem[];
  /**
   * Show all wells in a disabled state on the plate with disabled interactions.
   * Will override disabledWells property.
   * Tooltips will still appear on disabled wells.
   */
  disableAllWells?: boolean;
  /**
   * Show wells with no content allocated with a question mark inside. These indicate wells
   * that we are not yet aware of what that content will be.
   */
  showEmptyWellsAsPossiblyAllocated?: boolean;
  /**
   * Display the highlightedWells in an error state.
   */
  hasError?: boolean;
  /**
   * Within each well, show text to describe its content.
   */
  showContentLabels?: boolean;
  /**
   * By default, if showContentLabels is true, we will display a label on the well
   * describing the contents. This label can be overriden by passing in this function
   * to return an updated WellLabelContent based on the well (in 'A1' format).
   */
  getContentLabel?: (
    well: string,
    wellContents: WellContents | undefined,
    location: { row: number; col: number },
  ) => WellLabelContent;
  /**
   * By default the tooltip title will be formatted per LegacyWellTooltipTitle but
   * this can be overridden using this function component.
   */
  TooltipTitle?: React.FunctionComponent<WellTooltipTitleProps>;
  /**
   * Allow clicking on wells and row/column headers
   */
  isInteractive?: boolean;
  /**
   * AxisLabels will be shown,
   */
  showAxisLabels?: boolean;
  highlightMode?: WellHighlightMode;
  onColHeaderClick?: (col: number) => void;
  onRowHeaderClick?: (row: number) => void;
  onWellPointerDown?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellPointerUp?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  onWellMouseLeave?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  /**
   * Handler attached to the plate rectangle.
   */
  onPlatePointerDown?: (e: React.PointerEvent) => void;
  /** Ref attached to the rect that represents the geometric shape and size of the plate. */
  plateRectRef?: React.RefObject<SVGRectElement> | null;
  className?: string;
  focusedWells?: readonly WellLocationOnDeckItem[];
};

function getColorForWellContents(
  wellContents: WellContents | undefined,
  liquidColors: LiquidColors,
): string | undefined {
  if (!wellContents) {
    return;
  }
  switch (wellContents.kind) {
    case 'filter_matrix_summary':
      // TODO: this should return a pattern (e.g. crosshatch) for this filter
      // matrix T2667. For now we just make all filter matrixes coloured blue.
      return Colors.LIGHT_BLUE;

    // Handle liquid_summary and, for older simulations, undefined
    default:
      return liquidColors.getColorForWell(wellContents);
  }
}

/**
 * An interactive plate, with wells and column/row headers. This is used in the
 * Simulation details (Setup and Preview) and in the WellSelector. This component
 * should be used within an <svg> element.
 *
 * This component serves a similar purpose to the PlateVisualization component,
 * which is used for rendering plates within the plate library. These two
 * components could probably be merged at some point.
 */
export default function PlateLayout({
  plate,
  geometry,
  liquidColors,
  highlightedWells,
  showEmptyWellsAsPossiblyAllocated,
  disabledWells,
  disableAllWells,
  hasError,
  getContentLabel,
  showContentLabels,
  TooltipTitle,
  showAxisLabels = true,
  isInteractive,
  highlightMode = WellHighlightMode.SELECTION,
  onPlatePointerDown,
  onWellPointerDown,
  onWellPointerUp,
  onWellMouseEnter,
  onWellMouseLeave,
  onColHeaderClick,
  onRowHeaderClick,
  plateRectRef,
  className,
  focusedWells,
}: Props) {
  const { width: plateWidth, height: plateHeight } = geometry.getDimensions();

  const [tooltipAnchor, setTooltipAnchor] = useState<SVGElement>();
  const [tooltipWell, setTooltipWell] = useState<[row: number, col: number]>();

  const onWellEnter = useCallback(
    (loc: WellLocationOnDeckItem, e: React.MouseEvent<SVGGElement>) => {
      setTooltipAnchor(e.currentTarget);
      const newWell: [number, number] = [
        Number.parseInt(e.currentTarget.dataset.row ?? '0'),
        Number.parseInt(e.currentTarget.dataset.col ?? '0'),
      ];
      setTooltipWell(currentWell =>
        isEqual(currentWell, newWell) ? currentWell : newWell,
      );
      onWellMouseEnter?.(loc, e);
    },
    [onWellMouseEnter],
  );

  const onWellLeave = useCallback(
    (loc: WellLocationOnDeckItem, e: React.MouseEvent<SVGGElement>) => {
      setTooltipAnchor(undefined);
      onWellMouseLeave?.(loc, e);
    },
    [onWellMouseLeave],
  );

  const tooltipTitle = (
    <TooltipTitleComponent
      plate={plate}
      showEmptyWellsAsPossiblyAllocated={showEmptyWellsAsPossiblyAllocated}
      TooltipTitle={TooltipTitle}
      tooltipWell={tooltipWell}
    />
  );

  const wells = useMemo(() => {
    const wells: JSX.Element[] = [];
    for (let row = 0; row < geometry.rows; row++) {
      for (let col = 0; col < geometry.columns; col++) {
        const wellContents = getWellContentsHelper(plate, col, row);
        const wellAbsolutePos = geometry.getWellRect(col, row);
        const wellLocationOnDeckItem = { deck_item_id: plate.id, row, col };
        const isHighlighted = highlightedWells?.some(
          loc => loc.row === row && loc.col === col,
        );

        const isDisabled =
          disableAllWells ||
          disabledWells?.some(loc => loc.row === row && loc.col === col);
        const color = getColorForWellContents(wellContents, liquidColors);
        const contentLabelOverride = getContentLabel?.(
          formatWellPosition(row, col),
          wellContents,
          { row, col },
        );

        const contentsBelow = plate.contentsBelow?.[col]?.[row];
        const strokeColor = contentsBelow
          ? getColorForWellContents(contentsBelow, liquidColors)
          : undefined;

        const isFaded =
          focusedWells && !focusedWells?.some(loc => loc.col === col && loc.row === row);

        wells.push(
          <InteractiveWell
            key={row * geometry.columns + col}
            isHighlighted={isHighlighted}
            showEmptyWellsAsPossiblyAllocated={showEmptyWellsAsPossiblyAllocated}
            isDisabled={isDisabled}
            hasError={hasError}
            wellType={geometry.wellType}
            wellContents={wellContents}
            wellLocationOnDeckItem={wellLocationOnDeckItem}
            wellPos={wellAbsolutePos}
            color={color}
            strokeColor={strokeColor}
            onWellMouseEnter={onWellEnter}
            onWellMouseLeave={onWellLeave}
            onWellPointerUp={onWellPointerUp}
            onWellPointerDown={onWellPointerDown}
            showContentLabel={showContentLabels}
            contentLabelOverride={contentLabelOverride}
            highlightMode={highlightMode}
            isFaded={isFaded}
            row={row}
            col={col}
          />,
        );
      }
    }
    return wells;
  }, [
    geometry,
    plate,
    highlightedWells,
    disableAllWells,
    disabledWells,
    liquidColors,
    getContentLabel,
    focusedWells,
    showEmptyWellsAsPossiblyAllocated,
    hasError,
    onWellEnter,
    onWellLeave,
    onWellPointerUp,
    onWellPointerDown,
    showContentLabels,
    highlightMode,
  ]);

  return (
    <Popover
      title={tooltipTitle}
      open={!!tooltipAnchor}
      PopperProps={{ anchorEl: tooltipAnchor }}
      disableHoverListener={!showContentLabels}
    >
      <g>
        <Plate
          x="0.5"
          y="0.5"
          width={plateWidth - 1}
          height={plateHeight - 1}
          className={className}
          onPointerDown={onPlatePointerDown}
          ref={plateRectRef}
          rx={3}
        />
        {showAxisLabels && (
          <>
            <AxisLabels
              axis="horizontal"
              geometry={geometry}
              onLabelClick={onColHeaderClick}
              isInteractive={isInteractive}
            />
            <AxisLabels
              axis="vertical"
              geometry={geometry}
              onLabelClick={onRowHeaderClick}
              isInteractive={isInteractive}
            />
          </>
        )}
        {wells}
      </g>
    </Popover>
  );
}

type TooltipTitleProps = Pick<
  Props,
  'plate' | 'showEmptyWellsAsPossiblyAllocated' | 'TooltipTitle'
> & {
  tooltipWell: [row: number, col: number] | undefined;
};

const TooltipTitleComponent = React.memo((props: TooltipTitleProps) => {
  const { plate, showEmptyWellsAsPossiblyAllocated, TooltipTitle, tooltipWell } = props;
  if (tooltipWell) {
    const [row, col] = tooltipWell;
    const wellContents = getWellContentsHelper(plate, col, row);
    const wellLocationOnDeckItem = { deck_item_id: plate.id, row, col };
    const wellIsEmpty = isWellEmpty(wellContents);
    return (
      // Preferentially show the tooltipTitleOverride if defined, otherwise default back to LegacyWellTooltipTitle.
      (TooltipTitle && (
        <TooltipTitle
          wellContents={wellContents}
          wellLocationOnDeckItem={wellLocationOnDeckItem}
        />
      )) ?? (
        <div style={{ color: Colors.TEXT_PRIMARY }}>
          <LegacyWellTooltipTitle
            isPossiblyAllocated={wellIsEmpty && showEmptyWellsAsPossiblyAllocated}
            wellContents={wellContents}
            wellLocationOnDeckItem={wellLocationOnDeckItem}
          />
        </div>
      )
    );
  }

  return null;
});

const Plate = styled('rect')({
  stroke: Colors.GREY_20,
  fill: 'transparent',
  strokeWidth: 1,
  vectorEffect: 'non-scaling-stroke',
});
