import { graphql } from 'client/app/gql';

export const QUERY_EXPERIMENTS = graphql(/* GraphQL */ `
  query Experiments(
    $after: String
    $userId: String
    $search: String
    $sort: SortTypeEnum
    $linksToObjectId: UUID
    $linksToObjectType: LinkedObjectType
    $contentSource: ContentType
    $experimentIds: [ExperimentId]
  ) {
    experiments(
      after: $after
      userId: $userId
      search: $search
      sort: $sort
      linksToObjectId: $linksToObjectId
      linksToObjectType: $linksToObjectType
      contentSource: $contentSource
      experimentIds: $experimentIds
    ) {
      items {
        id
        name
        createdBy {
          id
          displayName
        }
        lastModifiedAt
        contentSource
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment DatasetTagFragment on DatasetTag {
    id
    name
    columnMetadata {
      type
      isKeyColumn
    }
    value
  }
`);

graphql(/* GraphQL */ `
  fragment DatasetSampleFragment on DatasetSample {
    id
    sample {
      id
      name
    }
    rowKey
    rowKeyForUI {
      timestamp
    }
  }
`);

graphql(/* GraphQL */ `
  fragment DatasetEventFragment on DatasetEvent {
    id
    name
    occurredAt
    rowKey
    payload
  }
`);

graphql(/* GraphQL */ `
  fragment DatasetFragment on Dataset {
    datasetId: id
    name
    device {
      id
      name
      model {
        series {
          category {
            name
          }
        }
      }
    }
    createdAt
    lastModifiedAt
  }
`);

graphql(/* GraphQL */ `
  fragment WorkflowSummaryFragment on Workflow {
    id
    name
    source
    lastModifiedAt
    createdBy {
      displayName
    }
  }
`);

graphql(/* GraphQL */ `
  fragment ExecutionSummaryFragment on Execution {
    id
    status
    lastModifiedAt
    user {
      displayName
    }
  }
`);

graphql(/* GraphQL */ `
  fragment SimulationSummaryFragment on Simulation {
    id
    name
    status
    isFavoritedByCurrentUser
    favoritedBy
    startedAt
    lastUpdatedAt
    timeEstimateSeconds
    user {
      displayName
    }
  }
`);

graphql(/* GraphQL */ `
  fragment ExperimentBlockFragment on ExperimentBlock {
    id
    ... on TitleBlock {
      title
    }
    ... on TextBlock {
      text
    }
    ... on WorkflowBlock {
      workflow {
        ...WorkflowSummaryFragment
      }
    }
    ... on SimulationBlock {
      simulation {
        ...SimulationSummaryFragment
        execution {
          ...ExecutionSummaryFragment
        }
      }
    }
    ... on DatasetDerivationBlock {
      datasetDerivation {
        id
      }
    }
  }
`);

export const QUERY_EXPERIMENT = graphql(/* GraphQL */ `
  query Experiment($id: ExperimentId!) {
    experiment(id: $id) {
      id
      name
      lastModifiedAt
      createdBy {
        id
        displayName
        firstName
        lastName
      }
      contentSource
      blocks {
        ...ExperimentBlockFragment
      }
    }
  }
`);

export const QUERY_LATEST_DATASETS = graphql(/* GraphQL */ `
  query Datasets(
    $search: String
    $filterStartDate: DateTime
    $filterEndDate: DateTime
    $filterDeviceId: String
    $filterDeviceCategory: String
    $filterNotDeviceCategory: String
    $after: String
  ) {
    datasets(
      search: $search
      after: $after
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      filterDeviceId: $filterDeviceId
      filterLatestVersionsOnly: true
      filterDeviceCategory: $filterDeviceCategory
      filterNotDeviceCategory: $filterNotDeviceCategory
    ) {
      items {
        ...DatasetFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_DEVICES_FOR_DATASETS_FILTER = graphql(/* GraphQL */ `
  query DevicesForDatasetsFilter($deviceCategory: String, $notDeviceCategory: String) {
    devices(deviceCategory: $deviceCategory, notDeviceCategory: $notDeviceCategory) {
      id
      name
      model {
        isUploadSupported
      }
    }
  }
`);

export const QUERY_DATASET_DERIVATION = graphql(/* GraphQL */ `
  query DatasetDerivation($id: DatasetDerivationId!) {
    datasetDerivation(id: $id) {
      id
      outputDatasetName
      inputs {
        ...DatasetFragment
        samples {
          id
        }
      }
      outputDataset {
        ...DatasetFragment
      }
    }
  }
`);

export const QUERY_DATASET_WITH_SETTINGS = graphql(/* GraphQL */ `
  query DatasetWithSettings($id: DatasetId!) {
    dataset(id: $id) {
      id
      ...DatasetFragment
      samples {
        ...DatasetSampleFragment
      }
      events {
        ...DatasetEventFragment
      }
      eventOptions {
        names
      }
      tags {
        ...DatasetTagFragment
      }
      sampleMatchingOptions {
        columnName
        columnType
        columnValues
      }
    }
  }
`);

export const QUERY_DATASET_SAMPLES = graphql(/* GraphQL */ `
  query DatasetSamples($id: DatasetId!) {
    dataset(id: $id) {
      id
      samples {
        ...DatasetSampleFragment
      }
    }
  }
`);

export const QUERY_DATASET_EVENTS = graphql(/* GraphQL */ `
  query DatasetEvents($id: DatasetId!) {
    dataset(id: $id) {
      id
      events {
        ...DatasetEventFragment
      }
    }
  }
`);

export const QUERY_SAMPLE_SEARCH = graphql(/* GraphQL */ `
  query Samples($search: String!, $size: Int) {
    samples(search: $search, size: $size) {
      items {
        id
        name
      }
    }
  }
`);

export const QUERY_DATASET_DERIVATION_SAMPLES = graphql(/* GraphQL */ `
  query DatasetDerivationSamples($datasetDerivationId: DatasetDerivationId!) {
    datasetDerivation(id: $datasetDerivationId) {
      id
      inputs {
        id
        samples {
          id
          sample {
            id
            name
          }
        }
      }
    }
  }
`);

export const QUERY_EXPERIMENTS_INFO = graphql(/* GraphQL */ `
  query ExperimentsInfo($experimentIds: [ExperimentId]) {
    experiments(experimentIds: $experimentIds) {
      items {
        id
        name
        createdBy {
          firstName
          lastName
          id
        }
      }
    }
  }
`);

export const QUERY_LATEST_EXPERIMENT_WORKTREE_FOR_SIMULATION = graphql(/* GraphQL */ `
  query LatestExperimentWorktreeForSimulation($simulationId: SimulationId!) {
    latestExperimentWorktreeForSimulation(simulationId: $simulationId) {
      id
      name
    }
  }
`);
